.search-policy-section {
  .search-policy-filters {
    background: #ffffff;
    border: 1px solid rgba(27, 43, 64, 0.2);
    box-sizing: border-box;
    border-radius: 0px 10px 10px 10px;
    padding: 32px;
    h2 {
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
      color: #424242;
    }
    .policy-form-group {
      margin-top: 32px;
    }
    .search-policy-btn-col {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
