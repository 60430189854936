.account-info-section {
  position: relative;
  margin-bottom: 27px;
  .account-info-bg {
    position: absolute;
    width: 100%;
    height: 482px;
    background: var(--form-bg-color);
    clip-path: var(--form-bg-clip-path);
    .account-info-house1 {
      position: absolute;
      right: 25px;
      bottom: 60px;
      width: 311.35px;
      height: 144px;
      background-image: var(--contact-info-house-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .account-info-house2 {
      position: absolute;
      left: 25px;
      bottom: 60px;
      width: 257.3px;
      height: 182px;
      background-image: var(--contact-info-building-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .account-info-form-section {
    min-height: 300px;
    margin-top: 41px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 20px 30px 20px 30px;
    .account-info-form-main {
      border: 1px solid rgba(41, 69, 71, 0.2);
      border-radius: 5px;
      padding: 17px 10px 17px 10px;
      .account-info-heading {
        margin-left: 37px;
        h4 {
          font-size: 22px;      
          font-weight: 700;
          line-height: 26px;
          color: #292929;
          text-align: center;
        }
        p {
          margin-top: 8px;
          font-size: 17px;      
          font-weight: 400;
          line-height: 17px;
          text-align: center;
          color: #424242;
        }
      }
      .account-pi-title {
        background-color: #f4f4f4;
        min-height: 51px;
        padding-left: 37px;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 24px;
        h6 {
          vertical-align: middle;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          color: #292929;
          padding: 0px;
          margin: 0px;
        }
      }
      .add-additional-Insured-btn {
        display: flex;
        justify-content: right;
        margin-bottom: 24px;
      }
      .account-pi-main {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 10px;
        .account-form-group {
          margin-bottom: 21px;
        }
      }
      .account-info-action-btns {
        display: flex;
        justify-content: center;
      }
    }
  }
.flood-bts-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .btn {
        margin-right: 12px;
        height: 40px;
    }
}
}

@media screen and (max-width: 280px) {
  .account-info-section
    .account-info-form-section
    .account-info-form-main
    .account-info-heading {
    margin-left: 0px;
  }
  .account-info-section
    .account-info-form-section
    .account-info-form-main
    .account-pi-title {
    padding-left: 10px;
  }
  .account-info-section
    .account-info-form-section
    .account-info-form-main
    .account-pi-main {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 200px) {
  .account-info-section .account-info-form-section {
    padding: 10px !important;
  }
}
