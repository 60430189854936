.home-updates-section {
    position: relative;
    margin-bottom: 27px;

    .home-updates-bg {
        position: absolute;
        width: 100%;
        height: 482px;
        background: var(--form-bg-color);
        clip-path: var(--form-bg-clip-path);

        .home-updates-house1 {
            position: absolute;
            right: 25px;
            bottom: 60px;
            width: 311.35px;
            height: 144px;
            background-image: var(--contact-info-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .home-updates-house2 {
            position: absolute;
            left: 25px;
            bottom: 60px;
            width: 257.3px;
            height: 182px;
            background-image: var(--contact-info-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .updates-form-section {
        min-height: 300px;
        margin-top: 27px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        padding: 37px 48px 39px 48px;

        .updates-form-main {
            border-radius: 5px;
            border: 1px solid rgba(41, 69, 71, 0.2);
            padding-top: 17px;
            padding-bottom: 17px;

            .updates-headings {
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    color: #292929;
                    text-align: center;
                }

                p {
                    font-size: var(--label-font-size);
                    font-weight: 400;
                    line-height: 19px;
                    color: #424242;
                    margin-top: 0px;
                    text-align: center;
                }
            }

            .flood-bts-div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
            
                .btn {
                    margin-right: 12px;
                    height: 40px;
                }
            }

            .updates-card {
                padding: 16px;
                max-width: 220px;
                min-height: 150px;
                background: #ffffff;
                border: 1px solid var(--home-updates-border);
                box-sizing: border-box;
                border-radius: 22px;
                margin: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                .card-h {
                    margin-bottom: 15px;
                    font-size: var(--label-font-size);
                    font-weight: 600;
                    line-height: 23px;
                }

                #updates-form-input {
                    margin-top: 16px;
                    text-align: center;
                }

                .updates-vector1 {
                    background-image: var(--home-usage-vector1);
                    width: 75px;
                    height: 43.37px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .updates-vector2 {
                    background-image: var(--home-usage-vector2);
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .updates-vector3 {
                    background-image: var(--home-usage-vector3);
                    width: 48px;
                    height: 48px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .updates-vector4 {
                    background-image: var(--home-usage-vector4);
                    width: 60px;
                    height: 62.5px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }

        .update-actions-btns {
            display: flex;
            justify-content: center;
            margin-top: 21px;
        }
    }
}

.home-updates-section {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

@media screen and (max-width: 280px) {
    .home-updates-section .updates-form-section {
        padding: 10px 20px 10px 20px;
    }
}