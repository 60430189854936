.flood-construction-section {
  position: relative;
  margin-bottom: 27px;
  .flood-construction-form-section {
    min-height: 300px;
    margin-top: 27px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 40px 0px 40px 0px;
    .flood-construction-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 24px;
      .flood-construction-title {
        .flood-construction-main-title {
          font-size: var(--title-font-size);      
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
        .flood-construction-main-desc {
          font-size: var(--label-font-size);      
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left;
          color: #424242;
        }
      }
      .flood-construction-pricing {
        width: 181px;
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12px;
        .flood-construction-pricing-title {
          font-size: var(--estimate-premium-title-font-size);
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;
          color: #292929;
        }
        .flood-construction-pricing-value {
          font-size: var(--estimate-premium-value-font-size);
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: center;
          color: #64d6cd;
          margin-bottom: 0px;
          span {
            font-size: var(--estimate-premium-span-font-size);
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }
    }
    .flood-construction-row {
      margin-bottom: 24px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      .flood-construction-question-div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p {
          margin: 0px;
          color: #424242;
          font-size: var(--label-font-size);      
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
        }
        span {
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #424242;
        }
        .flood-construction-row-actions {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;

          .flood-construction-row-switch {
            margin-left: 10px;
            margin-right: 2px;
          }
        }
      }
      .flood-construction-answer-div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 25px;
        flex-wrap: wrap;
        #flood-construction-date-picker {
          background: #f9f9f9;
          border-radius: 4px;
          outline: none;
        }
        p {
          margin: 0px;
          color: #424242;
          font-size: var(--label-font-size);      
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          margin-right: 10px;
          margin-bottom: 15px;
        }
      }
    }
    .flood-construction2-row {
      margin-bottom: 8px !important;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      .flood-construction2-question-div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p {
          margin: 0px;
          color: #424242;
          font-size: var(--label-font-size);      
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #424242;
        }
        .flood-construction2-row-actions {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;

          .flood-construction-row-switch {
            margin-left: 10px;
            margin-right: 2px;
          }
        }
      }
    }
    #flood-constructon-input {
      border-radius: 4px !important;
      background: #f9f9f9;
      border: 1px solid #cac9c9;
    }
    .form-btns {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 542px) {
  .flood-construction-question-div {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    p {
      text-align: center;
    }
    .flood-construction-row-actions {
      margin-top: 15px;
    }
  }
  .flood-construction-header {
    flex-direction: column;
    align-items: center !important;
    .flood-construction-title {
      .flood-construction-main-title {
        text-align: center !important;
      }
      .flood-construction-main-desc {
        text-align: center !important;
      }
    }
  }
  #contruction-two-div {
    flex-direction: column;
    align-items: center;
    .flood-construction2-row-actions {
      padding-top: 15px !important;
      flex-wrap: wrap;
    }
  }
  #contruction-two-fields {
    flex-direction: column;
    p {
      margin-bottom: 15px;
    }
  }
}
