.report-page-row1 {
  padding: 0 !important;
  .report-page-row1-subdiv {
    background-color: #fff;
    border: 1px solid rgba(27, 43, 64, 0.2);
    padding: 32px 25px 32px 25px;
    border-radius: 0px 0px 8px 8px;
  }
}
#report-section-card {
  margin-top: 20px;
  margin-bottom: 20px;
  .report-section-card-title-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background: #64d6cd;
    border-radius: 5px 5px 0px 0px;
    padding: 12px 32px 12px 32px;
    .report-section-card-title-name {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      color: #ffffff;
      margin-left: 20px;
    }
  }
}
.report-select-div {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
#report-select-label {
  margin-right: 39px;
  margin-bottom: 10px;
}
.report-page-selections {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.report-section-row {
  background-color: #fff;
  border: 1px solid rgba(27, 43, 64, 0.2);
  padding: 32px 25px 32px 25px;
  border-radius: 0px 0px 8px 8px;
}
.report-generate-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .btn {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 20px;
  }
}
