.sidebar-main {
  width: 359px;
  height: 100vh;
  background: #102229;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition-property: width, transform !important;
  transition-duration: 0.3s !important;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  overflow: hidden;
  overflow-y: auto;
  &::before {
    content: "";
    background: #102229;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .sidebar-toggle {
    position: absolute;
    right: 10px;
    top: 155px;
    background-color: #1a3742;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 2px;
    }
  }

  .sidebar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    border-bottom: 1px solid #8f8f8f;
    padding-bottom: 30px;
    img {
      object-fit: cover;
    }
  }
  .sidebar-user-profile-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-profile-img {
      width: 55px;
      height: 55px;
      border-radius: 50px;
      border: 4px solid #64d6cd;
    }
    .user-profile-name {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 8px;
      margin-top: 8px;
      text-align: center;
    }
    .user-profile-role {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #aaaaaa;
      text-align: center;
    }
  }
  .sidebar-lang-selector {
    margin-top: 24px;
    #dropdown-basic {
      width: 180px;
      background: transparent;
      border: 1px solid #224654;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      font-size: 15px;
      color: #aaaaaa;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sidebar-dropdown {
      .dropdown-item:focus {
        background-color: #102229 !important;
        color: white !important;
      }
    }
  }
  .sidebar-menu-links {
    margin-top: 30px;
    overflow: hidden;
    overflow-y: auto;
    transition: flex-grow 0.3s cubic-bezier(0.4, 0, 1, 1);
    padding-bottom: 80px;
    a {
      text-decoration: none !important;
    }
    .sidebar-main-link {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: #aaaaaa;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
      margin-bottom: 20px;
      cursor: pointer;
      .sidebar-link-icon {
        margin-right: 20px;
      }
      a {
        text-decoration: none;
        color: #aaaaaa;
        display: flex;
        align-items: baseline;
      }
    }
    .sidebar-main-link.active {
      border-left: 8px solid #64d6cd;
      .sidebar-link-icon {
        color: white;
      }
      .sidebar-link-name {
        color: white;
      }
    }
  }
  .sidebar-menu-links {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .sidebar-menu-links::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-menu-icons {
  margin-left: 20px;
  vertical-align: middle;
  margin-top: 1px;
}

.sidebar-menu {
  display: block;
  list-style: none;
  margin-left: 60px;
  li {
    cursor: pointer;
  }
}

.sidebar-main.compact {
  width: 80px !important;
  .sidebar-toggle {
    right: 0px;
    left: 25px;
    top: 20px;
  }
  .sidebar-logo {
    display: none;
  }
  .sidebar-user-profile-div {
    margin-top: 70px !important;
    .user-profile-name {
      display: none;
    }
    .user-profile-role {
      display: none;
    }
  }
  .sidebar-lang-selector {
    #dropdown-basic {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px;
    }
  }

  .sidebar-menu-links {
    margin-top: 80px;
    .sidebar-main-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0px;

      .sidebar-link-icon {
        display: block;
        margin-right: 0px !important;
      }
      .sidebar-link-name {
        display: none;
      }
    }
  }

  .sidebar-user-profile-div {
    margin-top: 15px;
    .user-profile-img {
      width: 35px;
      height: 35px;
      margin-bottom: 15px;
    }
  }
  .sidebar-menu {
    display: none !important;
  }
  .sidebar-menu-icons {
    display: none !important;
  }
}
.sidebar-user-info-subdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-menu {
  a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: baseline;
    &:hover {
      color: #fff;
    }
  }
  #sidebar-submenu-icons {
    margin-right: 37px;
    margin-left: 0px !important;
  }
}
