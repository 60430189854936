.property-details-section {
    position: relative;
    margin-bottom: 48px;

    .p-form {
        min-height: 300px;
        margin-top: 44.5px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;

        h1 {
            font-weight: 700;
            font-size: 24px;
            padding-top: 37px;
            text-align: start;
        }

        .flood-bts-div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        
            .btn {
                margin-right: 12px;
                height: 40px;
            }
        }

        .confirm-address-form {
            padding: 32px 42px 40px 42px;

            .confirm-address-field {
                margin-bottom: 24px;

                .confirm-address-input {
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 34px;
                    color: #292929;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 16.94px;
                    box-shadow: none;

                    &:focus {
                        border: 1px solid #9c9898;
                    }
                }

                .confirm-address-label {
                    color: var(--form-font-color);
                    margin-bottom: 8px;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 16.94px;
                }
            }

            .form-map {
                background-color: #fff;
                border: 1px solid #d5cdcd;
                box-sizing: border-box;
                border-radius: 8px;
                height: 232px;
            }

            .form-map>div>div {
                border-radius: 8px;
            }

            .form-btns {
                display: flex;
                justify-content: center;
                margin-top: 24px;
            }
        }
    }

    #root-type-input {
        #root-drop-title {
            width: 30px !important;
            height: 30px !important;
        }
    
        .roof-dropdown-img1 {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: var(--architectural-day-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-right: 10px;
        }
    
        .roof-dropdown-img2 {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: var(--other-day-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    
        .roof-dropdown-img3 {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: var(--metal-day-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    
        .roof-dropdown-img4 {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: var(--tile-day-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    
        .roof-dropdown-img5 {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: var(--threetab-day-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    
        .dropdown-menu {
            width: 100%;
        }
    
        .btn-check:active+.btn-primary,
        .btn-check:checked+.btn-primary,
        .btn-primary.active,
        .btn-primary:active,
        .show>.btn-primary.dropdown-toggle {
            border: 1px solid #cdcdcd !important;
            color: #292929 !important;
            box-shadow: none !important;
            background: white !important;
        }
    
        .root-type-dropdown {
            border: 1px solid #cdcdcd;
            box-sizing: border-box;
            border-radius: 8px;
            height: 34px;
            color: #292929;
            font-size: var(--label-font-size);
            font-weight: 400;
            line-height: 16.94px;
            box-shadow: none;
            background: white;
            width: 100%;
            text-align: start;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    
        .root-type-dropdown:hover {
            border: 1px solid #cdcdcd !important;
            color: #292929 !important;
            box-shadow: none !important;
            background: white !important;
        }
    
        .root-type-dropdown:focus {
            border: 1px solid #cdcdcd !important;
            color: #292929 !important;
            box-shadow: none !important;
            background: white !important;
        }
    
        #root-type-dropdown-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
    
            span {
                margin-left: 3rem;
            }
    
            &:focus {
                background: #64d6cd !important;
            }
    
            &:active {
                background: #64d6cd !important;
            }
        }
    }
}