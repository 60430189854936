.property-details-section {
  position: relative;
  margin-bottom: 48px;

  .p-form {
    min-height: 300px;
    margin-top: 44.5px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    h1 {
      font-weight: 700;
      font-size: var(--title-font-size);
      padding-top: 37px;
      text-align: start;
    }
    .confirm-address-form {
      padding: 32px 42px 40px 42px;
      .confirm-address-field {
        margin-bottom: 24px;
        .confirm-address-input {
          //border: 1px solid #cdcdcd;
          box-sizing: border-box;
          border-radius: 8px;
          height: 34px;
          color: #292929;
          font-size: var(--label-font-size);
          font-weight: 400;
          line-height: 16.94px;
          box-shadow: none;
          &:focus {
            border: 1px solid #9c9898;
          }
        }
        .confirm-address-label {
          color: var(--form-font-color);
          margin-bottom: 8px;
          font-weight: 500;
          font-size: var(--label-font-size);
          line-height: 16.94px;
        }
      }
      .form-map {
        background-color: #fff;
        border: 1px solid #d5cdcd;
        box-sizing: border-box;
        border-radius: 8px;
        height: 232px;
      }
      .form-map > div > div {
        border-radius: 8px;
      }
      .form-btns {
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
    }
    .contact-form-btns {
      display: flex;
      justify-content: center;
      margin-bottom: 41px;
    }
  }

  #contact-info-div {
    border: 1px solid var(--contact-info-border-color);
    border-radius: 5px;
    margin: 40px;
    padding-top: 37px;
    padding-bottom: 37px;
    h1 {
      font-weight: 700;
      font-size: var(--title-font-size);
      padding-top: 0px;
      text-align: center;
      padding-bottom: 40px;
      text-align: start;
      text-transform: capitalize;
    }
    .confirm-address-form {
      padding: 0px;
      .form-btns {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
    #contact-phone-number {
      display: block;  
      outline: none !important;
      width: 100%;
      padding-left: 10px;
      color: #292929;
      font-size: var(--label-font-size);      
      font-weight: 400;
      line-height: 16.94px;
      font-family: "Inter", sans-serif !important;
    }
  }

  /* Discount Form */
  .discount-form {
    margin-top: 44.5px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 40px 48px 40px 48px;
    h1 {
      text-align: start;
      font-weight: 700;
      font-size: var(--title-font-size);
      text-align: start;
      margin-bottom: 40px;
    }
    .discount-form-row {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--label-font-size);
      p {
        margin: 0px;
        color: #424242;
        line-height: 19.36px;
        font-size: var(--label-font-size);
        font-weight: 400;
      }
      .discount-form-actions {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        .discount-form-switch {
          margin-left: 10px;
          margin-right: 2px;
        }
        .form-check-input {
          box-shadow: none !important;
          border-color: #424242;
        }
        .form-check-input:checked {
          background-color: var(--switch-color);
          border-color: var(--switch-color);
        }
      }
    }
    .form-btns {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 390px) {
  .discount-form-row {
    flex-direction: column;
    text-align: center;
    .discount-form-actions {
      margin-top: 12px;
    }
  }
}
/* Pricing Page */
.pricing-details-section {
  position: relative;
  margin-bottom: 48px;
  .property-details-img {
    position: absolute;
    width: 100%;
    height: 482px;
    background: var(--form-bg-color);
    clip-path: var(--form-bg-clip-path);

    .pp-house {
      position: absolute;
      left: 10px;
      bottom: 13%;
      width: 296.63px;
      height: 223px;
      background-image: var(--pricing-page-house);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .pp-object {
      position: absolute;
      right: 10px;
      bottom: 13%;
      width: var(--pricing-page-object-width);
      height: var(--pricing-page-object-height);
      background-image: var(--pricing-page-object);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .pricing-page-heading {
    margin-top: 19px;
    z-index: 10;
    text-align: center;
    h5 {
      font-size: var(--title-font-size);
      font-weight: 700;
      line-height: 32px;
      color: var(--pricing-page-heading);
    }
    .pricing-page-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: var(--pricing-page-heading);
        margin-bottom: 0px;
      }
      .discount-form-switch {
        margin-left: 24px;
        margin-right: 18px;
        width: 100%;
      }
      .form-check-input {
        box-shadow: none !important;
        border-color: #e2e2e2;
        width: 56px;
        height: 24px;
      }
      .form-check-input:checked {
        background-color: var(--pricing-page-switch-color);
        border-color: var(--pricing-page-switch-color);
      }
      .bill-annually {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 0px;
      }
    }
  }
  .pricing-table-main {
    margin-top: 26px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    .pricing-table {
      td:nth-child(1) {
        padding-left: 2rem;
      }
      thead {
        vertical-align: baseline;
        .per-month {
          font-size: var(--title-font-size);
          font-weight: 700;
          line-height: 29px;
          color: #2861ac;
          span {
            font-size: var(--label-font-size);
            font-weight: 400;
            text-transform: uppercase;
          }
        }
        .per-year {
          color: #424242;
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 17px;
        }
        .first-installment {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          color: #2861ac;
          span {
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }

      th:nth-child(1) {
        padding: 0px !important;
        min-width: 280px;
        border-right: 1px solid #efefef;

        .pay-plan-col-main {
          height: 100%;
          padding-left: 2rem;
          padding-bottom: 30px;
          h5 {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            .span {
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }
        .pay-plan-col {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          .pay-plan-h {
            font-size: var(--title-font-size);      
            font-weight: 700;
            line-height: 24px;
          }
        }
      }

      th:nth-child(2) {
        padding: 0px !important;
        min-width: 200px;
        border-right: 1px solid #efefef;

        > div {
          text-align: center;
          h5 {
            background: linear-gradient(267.97deg, #2a3593 0%, #3596cc 100%);
            min-height: 39px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 21.78px;
          }
        }
      }

      th:nth-child(3) {
        border-right: 1px solid #efefef;

        padding: 0px !important;
        min-width: 200px;
        > div {
          text-align: center;

          h5 {
            background: linear-gradient(267.97deg, #efa617 0%, #edd16f 100%);
            min-height: 39px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 21.78px;
          }
        }
      }

      th:nth-child(4) {
        padding: 0px !important;
        min-width: 240px;
        > div {
          text-align: center;

          h5 {
            background: linear-gradient(268.08deg, #2fb69d 1.14%, #5bd5d5 100%);
            min-height: 39px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 21.78px;
          }
        }
      }

      /* Table Body */
      tbody tr {
        height: 49px;
      }
      tbody tr td {
        color: #424242;
        font-size: var(--label-font-size);      
        font-weight: 400;
        line-height: 17px;
        text-transform: capitalize;
      }
      .range-col {
        display: flex;
        justify-content: center;
        .range-full-row {
          width: 100%;
          padding-left: 24px;
          padding-right: 24px;
          .range-col-text {
            overflow: hidden;
          }
        }
        .slider {
          width: 100%;
        }
      }
      .special-row {
        background: #fffcf7;
        border-bottom: 0px solid #fffcf7;
        text-align: left;
        min-height: 34px;
        td {
          color: #424242;
          font-size: var(--label-font-size);      
          font-weight: 600;
          line-height: 17px;
        }
      }
      .special-row td {
        border-top: 1px solid #fffcf7 !important;
      }
      tbody td:nth-child(1) {
        text-align: start;
      }
      tbody tr {
        text-align: center;
        border-bottom: 1px solid #efefef;
        vertical-align: middle;
      }
      td:not(:last-child) {
        border-right: 1px solid #efefef;
      }
    }
  }
  .pricing-btns {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
.buy-btn {
  background: var(--buy-btn-bg-color);
  box-shadow: 0px 1px 8px 3px rgba(166, 166, 166, 0.1) !important;
  border: 1px solid var(--buy-btn-bg-color);
  border-radius: 26px;
  padding: 7.5px 33px 7.5px 33px;
  font-weight: 500;
  font-size: var(--label-font-size);      

  &:hover {
    background: var(--buy-btn-hover-color);
    box-shadow: 0px 1px 8px 3px rgba(166, 166, 166, 0.1) !important;
    border: 1px solid var(--buy-btn-hover-color);
  }
  &:focus {
    background: var(--buy-btn-hover-color);
    box-shadow: 0px 1px 8px 3px rgba(166, 166, 166, 0.1) !important;
    border: 1px solid var(--buy-btn-hover-color);
  }
  &:disabled {
    background: var(--buy-btn-hover-color);
    box-shadow: 0px 1px 8px 3px rgba(166, 166, 166, 0.1) !important;
    border: 1px solid var(--buy-btn-hover-color);
  }
}
.rc-slider-dot-active {
  border-color: #e1ab37 !important;
}
.rc-slider-dot {
  border-color: #e1ab37 !important;
}
.rc-slider-track {
  background-color: #e1ab37 !important;
}

.rc-slider-handle {
  border-color: #e1ab37;
  border: solid 2px #e1ab37 !important;
}

.rc-slider-handle:focus {
  box-shadow: none;
  border-color: #e1ab37;
  border: solid 2px #e1ab37 !important;
}
.rc-slider-mark-text {
  white-space: nowrap !important;
}

@media screen and (max-width: 638px) {
  .pay-plan-col {
    flex-direction: column;
    .pay-plan-h {
      margin-bottom: 10px;
    }
    .pay-range {
      padding-right: 0px !important;
      width: 70% !important;
    }
  }
}

@media screen and (max-width: 543px) {
  .property-details-img {
    .pp-house {
      display: none;
    }
    .pp-object {
      display: none;
    }
  }
}

#root-type-input {
  #root-drop-title {
    width: 30px !important;
    height: 30px !important;
  }
  .roof-dropdown-img1 {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: var(--architectural-day-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 10px;
  }
  .roof-dropdown-img2 {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: var(--other-day-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .roof-dropdown-img3 {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: var(--metal-day-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .roof-dropdown-img4 {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: var(--tile-day-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .roof-dropdown-img5 {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: var(--threetab-day-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .dropdown-menu {
    width: 100%;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    border: 1px solid #cdcdcd !important;
    color: #292929 !important;
    box-shadow: none !important;
    background: white !important;
  }
  .root-type-dropdown {
    border: 1px solid #cdcdcd;
    box-sizing: border-box;
    border-radius: 8px;
    height: 34px;
    color: #292929;
    font-size: var(--label-font-size);
    font-weight: 400;
    line-height: 16.94px;
    box-shadow: none;
    background: white;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .root-type-dropdown:hover {
    border: 1px solid #cdcdcd !important;
    color: #292929 !important;
    box-shadow: none !important;
    background: white !important;
  }

  .root-type-dropdown:focus {
    border: 1px solid #cdcdcd !important;
    color: #292929 !important;
    box-shadow: none !important;
    background: white !important;
  }

  #root-type-dropdown-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      margin-left: 3rem;
    }
    &:focus {
      background: #64d6cd !important;
    }
    &:active {
      background: #64d6cd !important;
    }
  }
}

.flood-pricing-col1 {
  min-width: 120px !important;
}
.flood-pricing-col2 {
  min-width: 180px !important;
}

.flood-pricing-col3 {
  min-width: 180px !important;
}

.flood-pricing-col4 {
  min-width: 200px !important;
  h5 {
    border-top-right-radius: 5px !important;
  }
}

#flood-pricing-range {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.flood-pay-plan-h {
  font-size: var(--title-font-size);      
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

#flood-pricing-plan-select {
  margin-top: 10px;
  width: 90%;
}

#flood-pricing-buy-now {
  padding-top: 25px;
  padding-bottom: 25px;
}

.flood-pricing-house {
  position: absolute;
  right: 25px;
  bottom: 60px;
  width: 304.28px;
  height: 234px;
  background-image: var(--rainy-building1-img);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.flood-pricing-object {
  position: absolute;
  left: 25px;
  bottom: 60px;
  width: 274.92px;
  height: 234px;
  background-image: var(--rainy-house1-img);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.rc-slider-mark {
  display: none !important;
}