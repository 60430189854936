.stat-page-section {
    margin-top: 3rem;
}

.stat-horizontal-bar {
  background: #ffffff;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 24px !important;
  .stat-horizontal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .stat-horizontal-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 17px;
      color: #424242;
      margin-bottom: 1.5rem;
    }
    .stat-horizontal-header-subdiv {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;

      .stat-horizontal-subtitle {
        font-size: 17px;
        font-weight: 500;
        line-height: 17px;
        color: #818181;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 2rem;
        .stat-square {
          width: 15px;
          height: 15px;
          background-color: #23c6c8;
          margin-right: 10px;
        }
      }
    }
  }
}

.stat-select-div {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  #stat-select-label {
    margin-right: 10px;
  }
  .stat-page-selections {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
