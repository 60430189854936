/* .optional-cvg{
    opacity: 1 !important; 
}



input[disabled]~.form-check-label {
    opacity: 1;
} */


/* .form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
} */

/* .optional-cvg:disabled~.form-check-label,
.optional-cvg[disabled]~.form-check-label {
    opacity: 1 !important;
} */

.optional-cvg > .form-check-label {
    opacity: 1 !important;
}

.optional-cvg-checkbox > input[type="checkbox"] {
    box-shadow: none !important;
    border-radius: 2px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  
  .optional-cvg-checkbox > input:checked {
    background-color: #64d6cd;
    border-color: #64d6cd;
  }