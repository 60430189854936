.zip-details-modal {
    #zip-details-modal-header {
        background: var(--modal-heading-color) !important;
        border-bottom: 1px solid var(--modal-heading-color);
        color: #000;
        border-radius: 0px;
        padding-left: 50px;
    }
    .zip-details-modal-body {
        padding: 30px 50px 30px 50px;
    }
    .zip-details-modal-footer {
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;
    }
    .zip-details-form-group {
        margin-bottom: 1rem;
    }
}

.zip-details-table-div {
    .zip-details-table {
        margin-bottom: 21px;
        thead tr {
            background-color: #f4f4f4;
            height: 51px;
        }

        thead tr th {
            font-size: var(--label-font-size);      
            font-weight: 500;
            line-height: 19px;
            color: #424242;
            vertical-align: middle;
            text-align: start;
            min-width: 110px;
        }

        tbody tr td input {
            max-width: 120px;
        }
    }
}