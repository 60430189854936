.product-offerings-page-section {
  .product-offerings-page-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    color: #424242;
    margin-left: 12px;
    margin-bottom: 25px;
    margin-top: 20px;
  }
}

.product-quote-tab-main-div {
  padding: 48px;
  .product-quote-tab-main-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    color: #424242;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  .product-quote-card-main-div {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: 292px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    .quote-page-cards-icon {
      color: white;
      z-index: 1;
      width: 131.74px;
      height: 105.57px;
      margin-bottom: 20px;
      object-fit: contain;
    }
    .product-quote-card-main-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      color: #424242;
      z-index: 1;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .qpc1 {
    background: linear-gradient(
      180deg,
      #c6bca0 0%,
      rgba(240, 230, 202, 0.5) 100%
    );
  }
  .qpc2 {
    background: linear-gradient(
      180deg,
      rgba(100, 214, 205, 0.7) 0%,
      rgba(100, 214, 205, 0.5) 100%
    );
  }

  .qpc3 {
    background: linear-gradient(
      180deg,
      rgba(51, 209, 77, 0.7) 0%,
      rgba(51, 209, 77, 0.5) 100%
    );
  }

  .qpc4 {
    background: linear-gradient(
      180deg,
      rgba(38, 97, 173, 0.7) 0%,
      rgba(38, 97, 173, 0.5) 100%
    );
  }
}

.flood-quote-tab-main-div {
  padding: 40px 24px 40px 24px;
  display: flex;
  flex-direction: column;
  .flood-quote-tab-main-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #424242;
  }
  .flood-quote-tab-main-link {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #64d6cd;
    text-decoration: none;
    margin-top: 13px;
  }
}

.existing-policy-section {
  .existing-policy-filters {
    background: #ffffff;
    border: 1px solid rgba(27, 43, 64, 0.2);
    border-radius: 0px 10px 10px 10px;

    padding: 32px;
    h2 {
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
      color: #424242;
    }
    .existing-policy-form-group {
      margin-top: 32px;
    }
    .existing-search-policy-btn-col {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

#existing-tab-table-outer-div {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1.5rem;
  table {
    tbody tr td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    thead tr th {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
