.login-page-section {
  min-height: 100%;
  .login-page-card {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 15px;
    margin-top: 115px;
    padding: 50px 100px 50px 100px;
    display: flex;
    flex-direction: column;
    img {
      width: 216px;
      //height: 119.23px;
      object-fit: cover;
      margin-bottom: 50px;
      cursor: pointer;
    }
    .login-card-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      color: #424242;
      margin-bottom: 10px;
    }
    .login-card-subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #424242;
    }
    .login-card-line-breaker {
      width: 100%;
      height: 2px;
      background-color: #f3f3f3;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    #login-page-form-subtext {
      display: flex;
      justify-content: flex-end;
      font-size: 15px;
      font-weight: 600;
      line-height: 17px;
      color: #424242;
      margin-top: 10px;
      margin-bottom: 40px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 400px) {
  .login-page-card {
    padding: 50px 20px 50px 20px !important;
  }
}
