.add-mortage-section {
  position: relative;
  margin-bottom: 36px;
  .flood-add-mortage-form-section {
    min-height: 300px;
    margin-top: 41px;
    z-index: 10;
    background: #ffffff;
    box-shadow: none;
    border-radius: 5px;
    padding: 32px 25px 32px 25px;
    .add-mortage-form {
      border: 1px solid var(--border-color);
      border-radius: 8px;
      padding: 14px 14px 40px 14px;
      min-height: 230px;
      .add-mortage-table-div {
        .add-mortage-table {
          margin-bottom: 21px;
          thead tr {
            background-color: #f4f4f4;
            height: 51px;
          }

          thead tr th {
            font-size: var(--label-font-size);      
            font-weight: 500;
            line-height: 19px;
            color: #424242;
            vertical-align: middle;
            text-align: start;
            min-width: 110px;
            padding-left: 20px;
          }

          tbody tr td {
            padding: 4px 0.5rem 2px 0.5rem !important;
          }
          tbody tr td input {
            max-width: 120px;
          }
          tbody tr td:not(:last-child) {
            padding-right: 30px !important;
          }
          .add-mortage-action-row {
            vertical-align: middle;
            .add-mortage-action {
              display: flex;
              justify-content: start;
              align-items: center;
              padding-left: 8%;
              img {
                cursor: pointer;
              }
            }
          }
        }
      }
      .add-mortage-add-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 11px;
        .btn {
          font-size: 16px;
          font-weight: 400;
        }
        #add-input-field {
          background: #64d6cd;
          border: 1px solid #64d6cd !important;
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 19px;
          padding: 3px 17px 3px 17px;
          border-radius: 30px;
          span {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .add-mortage-searchbar {
        position: relative;
        min-width: 300px;
        min-height: 33px;
        border-radius: 26px;
        border: 1px solid rgba(41, 69, 71, 0.2);
        .add-mortage-input {
          border: 0px solid #ffffff;
          margin-left: 10px;
          box-shadow: none;
          outline: none;
          color: #292929;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          vertical-align: bottom;
          width: 70%;
        }
        #add-mortage-search-icon {
          position: absolute;
          right: 0;
          height: 100%;
          border-radius: 8px;
          line-height: 10px !important;
          width: 72px;
          border-radius: 26px;
        }
      }
    }
    .add-mortage-actions-btns {
      display: flex;
      justify-content: center;
      margin-top: 21px;
    }
  }
  .add-mortage-action-btns {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
.add-mortage-modal {
  #add-mortage-modal-header {
    background: var(--modal-heading-color) !important;
    border-bottom: 1px solid var(--modal-heading-color);
    color: #ffffff;
    border-radius: 0px;
    padding-left: 50px;
  }
  .add-mortage-modal-body {
    padding: 30px 50px 30px 50px;
  }
  .add-mortage-modal-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .add-mortage-form-group {
    margin-bottom: 1rem;
  }
}

.add-insured-input {
  max-width: 300px !important;
}

@media screen and (max-width: 466px) {
  .add-mortage-add-btn {
    flex-direction: column;
    .add-mortage-searchbar {
      margin-bottom: 10px;
      min-width: 100% !important;
    }
  }
}

@media screen and (max-width: 280px) {
  .add-mortage-section .flood-add-mortage-form-section {
    padding: 10px !important;
  }
  .add-mortage-searchbar {
    #add-mortage-search-icon {
      width: 30% !important;
    }
  }
}
