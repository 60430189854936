.other-links-page-section {
  .faq-tab-accordion {
    padding-top: 30px;
    padding-bottom: 60px;
    .accordion-button {
      box-shadow: none !important;
    }
    button:focus:not(:focus-visible) {
      outline: none !important;
    }
    .accordion-button:not(.collapsed) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #424242;
      background: #f5fffe;
    }
    .collapse.show {
      background: #f5fffe;
      padding-bottom: 40px;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url(../../assets/chevron-down.svg);
    }
  }
  .faq-tab-accordion-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      background: #ebeaea;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 17px;
    }
  }
}

/* CONTACT TAB */
.contact-tab-form-group {
  margin-bottom: 30px;
}

/* BINDING TAB */
#binding-tab-table-outer-div {
  background-color: #fff;
  border-radius: 8px;
  border: 0px solid rgba(0, 0, 0, 0.1);
}
.binding-tab-table {
  margin-top: 30px;
  .checkbox-table-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .table-checkbox {
      margin-right: 10px;
    }
  }
  #table-select-options {
    width: fit-content !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .cdtm-head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      svg {
        margin-right: 13px;
        width: 20px !important;
      }
    }
    text-align: center;
    thead {
      background: #f0f0f0;
      text-align: start;
      tr th {
        vertical-align: middle;
        font-size: 17px;
        font-weight: 400;
        line-height: 18px;
        color: #424242;
        padding-top: 15px;
        padding-bottom: 15px;
        svg {
          cursor: pointer;
        }
      }
    }
    thead tr th:first-child {
      padding-left: 30px;
    }
    thead tr th:last-child {
      padding-right: 30px;
    }
    tbody tr td:first-child {
      padding-left: 30px;
    }
    tbody tr td:last-child {
      padding-right: 30px;
    }
    tbody {
      background-color: #ffff;
      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      tr {
        td {
          vertical-align: middle;
          font-size: 17px;
          font-weight: 400;
          line-height: 18px;
          color: #424242;
          text-align: start;
        }
      }
    }
    tbody tr:last-child td {
      border-bottom: 0px solid white !important;
    }
  }
  table tr th,
  table tr td {
    border-right: 0px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  table tr th {
    text-align: left;
  }

  .table-main-footer-div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 0px 30px 0px 30px;
    .table-main-footer-entries {
      font-size: 17px;
      font-weight: 400;
      line-height: 18px;
      color: #424242;
      margin-bottom: 20px;
    }
    .table-main-footer-pagination {
      margin-bottom: 20px;

      .pagination {
        font-size: 18px !important;
        li {
          padding-right: 8px;
          .page-link {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .page-item.active .page-link {
          background-color: #fff !important;
          color: #64d6cd;
          border: 1px solid #64d6cd;
        }
      }
    }
  }
  .table-main-link {
    color: #2a72d1 !important;
    text-decoration: none !important;
    font-weight: 500;
  }
}

.binding-tab-header {
  background: #64d6cd;
  border-radius: 5px 5px 0px 0px;
  padding: 12px 32px 12px 32px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}

#binding-tab-entries {
  padding: 20px 32px 20px 32px;
}

.view-details-dropdown {
  #dropdown-basic {
    background: transparent;
    border: 0px solid #fff;
    box-shadow: none !important;
    border-radius: 2px;
    color: #2a72d1;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    width: fit-content;
  }
  .dropdown-item:focus {
    background-color: #64d6cd !important;
    color: white !important;
  }
  .dropdown-toggle::after {
    margin-left: 6px;
    color: #aaaaaa;
  }
}

.other-links-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  color: #424242;
  margin-left: 12px;
  margin-bottom: 25px;
  margin-top: 20px;
}
