.claim-info-section {
	position: relative;
	margin-bottom: 26px;
	.claim-info-bg {
	  position: absolute;
	  width: 100%;
	  height: 482px;
	  background: var(--form-bg-color);
	  clip-path: var(--form-bg-clip-path);
	  .claim-info-house1 {
		position: absolute;
		right: 25px;
		bottom: 60px;
		width: 274.92px;
		height: 234px;
		background-image: var(--discount-page-house-img);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	  }
	  .claim-info-house2 {
		position: absolute;
		left: 25px;
		bottom: 60px;
		width: 257.3px;
		height: 182px;
		background-image: var(--contact-info-building-img);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	  }
	}
	.claim-info-form-section {
	  min-height: 450px;
	  margin-top: 41px;
	  z-index: 10;
	  background: #ffffff;
	  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
	  border-radius: 8px;
	  padding: 33px 29px 37px 29px;
	  .claim-info-form-main {
		padding-bottom: 17px;
		.claim-headings {
		  margin-bottom: 30px;
		  h3 {
			font-size: var(--title-font-size);      
			font-weight: 600;
			line-height: 26px;
			text-align: left;
			color: #292929;
		  }
		}
		.claim-form {
		  border: 1px solid var(--border-color);
		  border-radius: 8px;
		  padding: 14px;
		  min-height: 230px;
		  .claim-card-row {
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
			border-radius: 8px;
			margin-bottom: 11px;
			padding: 16px 11px 16px 11px;
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			p {
			  margin: 0px;
			  color: #424242;
			  font-size: var(--label-font-size);      
			  font-weight: 600;
			  line-height: 19px;
			}
			span {
			  font-size: var(--label-font-size);      
			  font-weight: 400;
			  line-height: 19px;
			}
			.property-form-actions {
			  display: flex;
			  justify-content: flex-start;
			  align-items: baseline;
  
			  .property-form-switch {
				margin-left: 10px;
				margin-right: 2px;
			  }
			}
		  }
		  .claim-table-div {
			#claim-table-datepicker {
			  max-width: 120px;
			}
			.claim-table {
			  margin-bottom: 21px;
			  thead tr {
				background-color: #f4f4f4;
				height: 51px;
			  }
			  thead tr th {
				font-size: var(--label-font-size);      
				font-weight: 500;
				line-height: 19px;
				color: #424242;
				vertical-align: middle;
				text-align: start;
				min-width: 120px;
				padding-left: 2%;
			  }
  
			  .tr-action-row {
				vertical-align: middle;
				.tr-action-btns {
				  display: flex;
				  justify-content: start;
				  align-items: center;
				  padding-left: 8%;
				  img {
					cursor: pointer;
				  }
				}
			  }
			}
		  }
		  .claim-add-btn {
			text-align: end;
			margin-bottom: 11px;
			.btn {
			  font-size: var(--label-font-size);      
			  font-weight: 400;
			  line-height: 19px;
			  padding: 3px 17px 3px 17px;
			  border-radius: 30px;
			  span {
				font-size: 20px;
				font-weight: 400;
				line-height: 24px;
			  }
			}
		  }
		}
	  }
	  .claim-info-actions-btns {
		display: flex;
		justify-content: center;
		margin-top: 25px;
	  }
	}
  }
  
  @media screen and (max-width: 542px) {
	.claim-card-row {
	  flex-direction: column;
	  align-items: center !important;
	  justify-content: center !important;
	  p {
		text-align: center;
	  }
	  .property-form-actions {
		margin-top: 15px;
	  }
	}
  }
  
  @media screen and (max-width: 280px) {
	.claim-info-section .claim-info-form-section {
	  padding: 10px !important;
	}
  }
  