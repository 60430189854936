.row {
  margin: 0 !important;
}
.d-main-select {
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #424242 !important;
}
.custom-tabs {
  .nav-tabs {
    border-bottom: none !important;
  }
  .nav-tabs .nav-link {
    font-weight: 400;
    line-height: 23px;
    background: #d6f0ee;
    color: #424242;
    padding: 18px 32px;
    font-size: 20px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background: #64d6cd;
    color: #ffffff;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: #64d6cd !important;
    font-weight: 700;
  }
}
.dashboard-group-label {
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  color: #424242 !important;
}

.dashboard-group-input {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  box-shadow: none !important;
  height: 40px;
}

.dashboard-group-input-flood {
  border: 1px solid #ced4da;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  box-shadow: none !important;
  height: 40px;
}

.dashboard-group-select {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  box-shadow: none !important;
  height: 40px;
}

#dashboard-table-section {
  margin-top: 20px;
  margin-bottom: 20px;
  .table-main-title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #64d6cd;
    border-radius: 5px 5px 0px 0px;
    padding: 12px 32px 12px 32px;
    .table-main-title-name {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      color: #ffffff;
    }
    .table-main-title-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .table-square {
        width: 15px;
        height: 15px;
        background: rgba(51, 209, 77, 0.5);
        border: 3px solid #f0f0f0;
        margin-right: 36px;
      }
    }
  }
}

#common-btn {
  background: #64d6cd;
  border-radius: 5px;
  box-shadow: none !important;
  border: 1px solid #64d6cd !important;
  padding: 15px;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  &:hover {
    background-color: #66e3d8;
    border: 1px solid #66e3d8 !important;
  }
}

#common-outline-btn {
  border-color: #fff !important;
  color: #fff;
  box-shadow: none !important;
  border-radius: 4px !important;

  font-size: 16px;

  font-weight: 500;
  line-height: 19px;

  &:hover {
    background-color: #fff !important;
    color: #64d6cd;
  }
}

#common-cancel-btn {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  padding: 15px;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #424242;
  &:hover {
    color: #424242;
    background: #f0f0f0;
    border: 1px solid #ced4da !important;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #64d6cd !important;
}
.drp-buttons {
  .applyBtn {
    background-color: #64d6cd !important;
    border: 1px solid #64d6cd !important;
  }
}
.span-diff-color {
  color: #64d6cd;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
.span-alert {
  color: #df2626 !important;
}

#dashboard-datepicker {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  height: 40px;
}

.card-header-main {
  background: #64d6cd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 6px 32px;
  border-radius: 5px 5px 0px 0px;
  flex-wrap: wrap;
  .card-header-main-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #ffffff;
    margin-bottom: 10px;
    .card-header-main-text {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-left: 13px;
    }
  }
  .card-header-btns-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .card-header-action {
      margin-right: 13px;
      margin-bottom: 10px;

      .card-header-action-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #ffffff;
        margin-left: 13px;
        cursor: pointer;
      }
    }
    .card-header-btn0 {
      margin-bottom: 10px;
      svg {
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }
}

// submit button on attachments
.card-header-main-flood {
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px 6px 32px;

  #common-outline-btn-flood {
    background: #64d6cd;
    box-shadow: none !important;
    border-radius: 5px;
    border: 1px solid #64d6cd !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    color: #ffffff;
    &:hover {
      background-color: #66e3d8;
      border: 1px solid #66e3d8 !important;
    }
  }
}

.card-body-main {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0px 0px 8px 8px;
  padding: 30px 23px 30px 23px;
}

.table-show-entries {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .table-show-entries-text {
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    color: #424242;
    margin-right: 8px;
  }
  .table-show-entries-select {
    width: fit-content;
    background-color: transparent !important;
    border-top: 0px solid white !important;
    border-left: 0px solid white !important;
    border-right: 0px solid white !important;
    border-bottom: 3px solid #ced4da;
    box-shadow: none !important;
    border-radius: 0px !important;
    outline: none !important;
  }
}

#custom-checker {
  box-shadow: none !important;
  border-radius: 2px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

#custom-checker:checked {
  background-color: #64d6cd;
  border-color: #64d6cd;
}

.dashboard-datepicker-main {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  box-shadow: none !important;
  width: 100% !important;
}

.dashboard-datepicker-main-bg {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #424242 !important;
  box-shadow: none !important;
  width: 100% !important;
  background-color: #E9ECEF;
}
.react-datepicker__day--selected {
  background-color: #64d6cd !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #64d6cd !important;
}

.card-colored-header {
  background: #64d6cd;
  border-radius: 5px 5px 0px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 32px 12px 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #ffffff;
  }
}
.card-colored-body {
  background: #ffffff;
  border: 1px solid rgba(27, 43, 64, 0.2);
  box-sizing: border-box;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
}

.columns-table-main {
  tr {
    vertical-align: baseline;
  }
  tr th {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #424242;
  }
  tr td {
    padding-left: 20px;

    font-size: 16px;

    font-weight: 400;
    line-height: 19px;
    color: #424242;
  }
}

.datepicker-full-width {
  padding: 0.5rem 0.5rem !important;
  width: 100%;
}

.custom-tab-header-fill {
  background: #64d6cd;
  border-radius: 0px 5px 0px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 32px 12px 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #ffffff;
  }
}
.dashboard-tab-header-fill {
  background: #64d6cd;
  border-radius: 5px 5px 0px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 32px 12px 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #ffffff;
  }
}
.custom-tab-body {
  background: #ffffff;
  border: 1px solid rgba(27, 43, 64, 0.2);
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
}

.label-asterisk {
  color: #ff1e1e;
}

.custom-radio-check0 {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #424242;
  .form-check-input:checked {
    background-color: #64d6cd;
    border-color: #64d6cd;
  }
}

#primary-colored-btn {
  background: #64d6cd;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #64d6cd !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  &:hover {
    background-color: #66e3d8;
    border: 1px solid #66e3d8 !important;
  }
}

#primary-outlined-btn {
  background: #ffffff;
  border: 1.5px solid #64d6cd;
  border-radius: 4px;
  color: #64d6cd;
  box-shadow: none !important;

  &:hover {
    background: #64d6cd;
    border: 1.5px solid #ffffff;
    color: #ffffff;
  }
}

#primary-grey-btn {
  background: #dbdbdb;
  border: 1.5px solid #dbdbdb;
  border-radius: 4px;
  color: white;
  box-shadow: none !important;

  &:hover {
    background: #cbcbcb;
    border: 1.5px solid #cbcbcb;
    color: #ffffff;
  }
}

.common-headings {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

/* REACT BOOTSTRAP TABLE NEXT */
.management-functional-table {
  table {
    table-layout: auto !important;
  }
  .react-bs-table-no-data {
    text-align: start !important;
    padding: 20px 32px !important;
  }
  .selection-input-4 {
    box-shadow: none !important;
    border-radius: 2px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    vertical-align: middle;
  }

  .selection-input-4:checked {
    background-color: #64d6cd !important;
    border-color: #64d6cd !important;
  }
}

.react-bootstrap-table-pagination-list {
  .pagination {
    justify-content: flex-end !important;
    align-items: baseline;
  }
}
.react-bootstrap-table-pagination {
  align-items: baseline;
  margin-bottom: 10px;
}

.react-bootstrap-table-pagination-total {
  display: flex;
  margin-top: 20px;
  margin-left: 3px;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  color: #424242;
}

@media screen and (max-width: 1147px) {
  .react-bootstrap-table-pagination {
    flex-direction: column;
    .pagination {
      margin-top: 20px;
      justify-content: flex-start !important;
    }
  }
}
// MODAL
.custom-dashboard-modal {
  z-index: 10555 !important;
  .btn-close {
    background: url(../assets/cross-sign.svg) center/1em auto no-repeat;
    opacity: 1;
    box-shadow: none !important;
  }
  .modal-content {
    border: none !important;
  }
  .custom-dashboard-modal-header {
    background-color: #64d6cd;
    padding: 16px 32px;
    border-bottom: none !important;
    .modal-title {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }
}

.custom-select-autocomplete {
  .css-9gakcf-option {
    background-color: #64d6cd;
    &:focus {
      background-color: #64d6cd;
    }
    &:active {
      background-color: #64d6cd;
    }
  }
  .css-yk16xz-control {
    border: 1px solid #ced4da !important;
    border-radius: 6px !important;

    height: 40px;
    color: #424242;
    font-size: 17px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    color: #424242 !important;
    box-shadow: none !important;
    &:hover {
      border: 1px solid #ced4da !important;
      border-radius: 6px !important;
      box-shadow: none !important;
    }
    &:focus {
      border: 1px solid #ced4da !important;
      border-radius: 6px !important;
      box-shadow: none !important;
    }
  }
  .css-1pahdxg-control {
    border-radius: 6px !important;

    &:hover {
      border: 1px solid #ced4da !important;
      border-radius: 6px !important;
      box-shadow: none !important;
    }
    &:focus {
      border: 1px solid #ced4da !important;
      border-radius: 6px !important;
      box-shadow: none !important;
    }
  }
}

@media screen and (max-width: 1201px) {
  .custom-select-autocomplete {
    width: 100% !important;
  }
}

.center-div-class {
  display: flex;
  justify-content: center;
}

#primary-grey-btn-flood {
  background-color: #ffffff;
  color: #424242;
  box-shadow: none !important;
  border: 1px solid #cdcdcd;
  font-weight: 500;
  line-height: 22px;

  &:hover {
    background-color: #ffffff;
    box-shadow: none;
    border: 1px solid #424242;
    color: #424242;
  }
  &:focus {
    background-color: #ffffff;
    box-shadow: none;
    color: #424242;
    border: 1px solid #424242;
  }
}