.flood-div {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  min-height: 200px;
  border-radius: 20px;
  padding: 20px;
  .flood-div-border {
    border-radius: 8px;
    border: 1px solid #64d6cd;
    padding: 20px;
    p {
      text-align: justify;
      span {
        color: #42ccc2;
      }
    }
  }
  .flood-error-heading {
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    h6 {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 61px;
      color: #cc4e42;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .flood-bts-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .btn {
      margin-right: 12px;
      height: 40px;
    }
  }
}

