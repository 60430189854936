.home-type-headings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  .home-type-title {
    font-size: var(--title-font-size);
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: #292929;
    text-transform: capitalize;
    text-align: center;
  }
  .home-type-desc {
    font-size: var(--label-font-size);      
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.005em;
    margin-top: 3px;
    color: #424242;
    text-align: center;
  }
}

.flood-quote-page-cards-div {
  border: 1px solid #64d6cd;
  border-radius: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
  .flood-quote-page-cards-icon {
    color: white;
    width: 131.74px;
    height: 105.57px;
    margin-bottom: 20px;
    object-fit: contain;
  }
  .flood-quote-page-cards-title {
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    color: #424242;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}