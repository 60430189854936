.flood-payment-section {
    position: relative;
    margin-bottom: 27px;
    .flood-payment-form {
      min-height: 300px;
      margin-top: 44.5px;
      z-index: 10;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
      border-radius: 5px;
      padding: 32px 42px 40px 42px;
      h1 {
        font-weight: 700;
        font-size: 18px;
        padding-top: 37px;
        text-align: start;
      }
      .payment-form {
        padding: 32px 42px 40px 42px;
        .payment-field {
          margin-bottom: 14px;
          .payment-field-input {
            box-sizing: border-box;
            border-radius: 8px;
            height: 34px;
            color: #292929;
            font-size: var(--label-font-size);      
            font-weight: 400;
            line-height: 16.94px;
            box-shadow: none;
            &:focus {
              border: 1px solid #9c9898;
            }
          }
          .payment-field-label {
            color: var(--form-font-color);
            margin-bottom: 8px;
            font-weight: 500;
            font-size: var(--label-font-size);      
            line-height: 16.94px;
          }
        }
        .form-btns {
          display: flex;
          justify-content: center;
          margin-top: 24px;
        }
      }
      .contact-form-btns {
        display: flex;
        justify-content: center;
        margin-bottom: 41px;
      }
    }
  
    .pay-now-action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: 28px;
      margin-top: 23px;
      #credit-card-btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 7.5px 25px 7.5px 25px;
        img {
          margin-right: 8px;
        }
      }
    }
  
    #flood-payment-div {
      border: 1px solid var(--contact-info-border-color);
      border-radius: 5px;
      margin: 60px 40px 60px 40px;
      padding-top: 40px;
      h1 {
        font-weight: 700;
        font-size: var(--title-font-size);      
        padding-top: 0px;
        text-align: center;
        padding-bottom: 40px;
        text-align: start;
        text-transform: capitalize;
      }
      .payment-form {
        padding: 0px;
        .form-btns {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
  
  @media screen and (max-width: 300px) {
    .flood-payment-section #flood-payment-div {
      margin: 40px 5px 40px 5px;
    }
  }
  