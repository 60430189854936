.sign-off-info-section {
    position: relative;
    margin-bottom: 27px;

    .sign-off-info-bg {
        position: absolute;
        width: 100%;
        height: 482px;
        background: var(--form-bg-color);
        clip-path: var(--form-bg-clip-path);

        .sign-off-info-house1 {
            position: absolute;
            right: 25px;
            bottom: 60px;
            width: 311.35px;
            height: 144px;
            background-image: var(--contact-info-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .sign-off-info-house2 {
            position: absolute;
            left: 25px;
            bottom: 60px;
            width: 257.3px;
            height: 182px;
            background-image: var(--contact-info-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .sign-off-info-form-section {
        min-height: 300px;
        margin-top: 41px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        padding: 20px 30px 20px 30px;

        .sign-off-info-form-main {
            border: 1px solid rgba(41, 69, 71, 0.2);
            border-radius: 5px;
            padding: 17px 10px 17px 10px;

            .sign-off-info-heading {
                margin-left: 37px;

                h4 {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 26px;
                    color: #292929;
                    text-align: center;
                }

                p {
                    margin-top: 8px;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #424242;
                    text-align: center;
                }
            }

            .account-pi-title {
                background-color: #f4f4f4;
                min-height: 51px;
                padding-left: 37px;
                vertical-align: middle;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 24px;

                h6 {
                    vertical-align: middle;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 26px;
                    color: #292929;
                    padding: 0px;
                    margin: 0px;
                }
            }

            .account-pi-main {
                padding-left: 24px;
                padding-right: 24px;
                margin-bottom: 10px;

                .account-form-group {
                    margin-bottom: 21px;
                }
            }

            .sign-off-info-action-btns {
                display: flex;
                justify-content: center;
            }

            .flood-bts-div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
            
                .btn {
                    margin-right: 12px;
                    height: 40px;
                }
            }
        }
    }
}

@media screen and (max-width: 280px) {
    .sign-off-info-section .sign-off-info-form-section .sign-off-info-form-main .sign-off-info-heading {
        margin-left: 0px;
    }

    .sign-off-info-section .sign-off-info-form-section .sign-off-info-form-main .account-pi-title {
        padding-left: 10px;
    }

    .sign-off-info-section .sign-off-info-form-section .sign-off-info-form-main .account-pi-main {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 200px) {
    .sign-off-info-section .sign-off-info-form-section {
        padding: 10px !important;
    }
}