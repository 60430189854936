.schedule-date-row {
  .react-datepicker-wrapper {
    padding: 0px !important;
  }
}

.select-document-type-div {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  padding: 27px;
  margin-top: 16px;
  .document-type-subdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #424242;
      margin-left: 15px;
    }
  }
}

#drop-document-type-div {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  padding: 30px 27px 30px 27px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: rgba(66, 66, 66, 0.8);
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    svg {
      margin-right: 10px;
    }
  }
}

.reply-document-name {
  margin-top: 1rem;
}

.reply-page-editor {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  min-height: 100px !important;
}

.editorClassName {
  overflow: hidden;
}
