.property-details-section {
  position: relative;
  margin-bottom: 48px;

  .p-form {
    min-height: 300px;
    margin-top: 44.5px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      padding-top: 37px;
      text-align: start;
    }

    .confirm-address-form {
      padding: 32px 42px 40px 42px;

      .confirm-address-field {
        margin-bottom: 24px;

        .confirm-address-input {
          box-sizing: border-box;
          border-radius: 8px;
          height: 34px;
          color: #292929;
          font-size: var(--label-font-size);
          font-weight: 400;
          line-height: 16.94px;
          box-shadow: none;

          &:focus {
            border: 1px solid #9c9898;
          }
        }

        .confirm-address-label {
          color: var(--form-font-color);
          margin-bottom: 8px;
          font-weight: 500;
          font-size: var(--label-font-size);
          line-height: 16.94px;
        }

        span {
          font-size: var(--label-font-size);
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #424242;
        }

        .flood-construction-row-actions {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .flood-construction-row-switch {
            margin-left: 10px;
            margin-right: 2px;
          }
        }
      }

      .form-map {
        background-color: #fff;
        border: 1px solid #d5cdcd;
        box-sizing: border-box;
        border-radius: 8px;
        height: 232px;
      }

      .form-map>div>div {
        border-radius: 8px;
      }

      .form-btns {
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
    }
  }
}