.policy-details-section {
  .policy-details-icons-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #64d6cd;
      margin-top: 7px;
    }
  }
  .policy-details-group-form {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    white-space: nowrap;
    margin: 10px;
    input {
      min-width: 225px;
    }
    select {
      max-width: 400px;
    }
  }
  .policy-details-header {
    background: #ffffff;
    border-radius: 8px;
  }
  #policy-details-tabs-section {
    margin-top: 24px !important;
    #policy-details-tab1-col1 {
      background: #ffffff;
      border: 1px solid rgba(27, 43, 64, 0.2);
      box-sizing: border-box;
      border-radius: 0px 10px 10px 10px;
      padding: 30px;
    }
  }
}

.policy-details-info-table {
  tr {
    vertical-align: baseline;
  }
  tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #424242;
  }
  tr td {
    padding-left: 20px;

    font-size: 16px;

    font-weight: 400;
    line-height: 19px;
    color: #424242;
  }
}

.billing-tab-row1 {
  padding: 0 !important;
  .billing-tab-row1-header {
    background: #64d6cd;
    border-radius: 5px 5px 0px 0px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    padding: 12px 32px 12px 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #ffffff;
    }
  }
  .billing-tab-row1-body {
    background: #ffffff;
    border: 1px solid rgba(27, 43, 64, 0.2);
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    padding: 20px;
  }
}

.billing-tab-row2 {
  margin-top: 25px;
  padding: 0 !important;
}

.policy-detail-table-main {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  text-align: center;
  thead {
    background: #f0f0f0;
    height: 48px;
    tr th {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 18px !important;
      color: #424242;
      vertical-align: middle;
      text-transform: capitalize !important;
    }
  }
  tbody {
    background-color: #ffff;

    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;

    tr {
      height: 48px;
      td {
        vertical-align: middle;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        color: #424242;
        text-transform: capitalize !important;
      }
    }
  }
}

#coverage-tab-row2 {
  padding: 0 !important;
}

#coverage-tab-table-section {
  margin-top: 30px;
  background: #ffffff;
  .coverage-tab-table-title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
  }
}

.coverage-tab-table-main {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  text-align: center;
  thead {
    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      rgba(240, 240, 240, 0) 100%
    );
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #424242;
    height: 48px;
    tr th {
      vertical-align: middle;
    }
  }
  tbody {
    background-color: #ffff;

    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;

    tr {
      height: 48px;
      td {
        vertical-align: middle;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        color: #424242;
      }
    }
  }
}

.cdtmm {
  table {
    margin-bottom: 0px !important;

    tbody tr {
      border-bottom: none !important;
    }
  }
}
.coverage-detail-table-main {
  table {
    table-layout: auto;
  }
  .cdtm-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    svg {
      margin-right: 13px;
      width: 20px !important;
    }
  }
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  text-align: center;
  thead {
    background: #f0f0f0;
    height: 48px;
    text-align: start;

    tr th {
      vertical-align: middle;
      border: none !important;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      color: #424242;
      svg {
        cursor: pointer;
      }
    }
  }
  thead tr th:first-child {
    padding-left: 30px;
  }
  thead tr th:last-child {
    padding-right: 30px;
  }
  tbody tr td:first-child {
    padding-left: 30px;
  }
  tbody tr td:last-child {
    padding-right: 30px;
  }
  tbody {
    background-color: #ffff;

    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;

    tr {
      height: 48px;
      td {
        vertical-align: middle;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        color: #424242;
        border: none !important;
        text-align: start;
      }
    }
  }
}
#coverage-tab-selection {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  label {
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
  }
  select {
    max-width: 200px;
  }
}
#forms-tab-btn-group {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn {
    margin-right: 10px;
    width: 330px;
  }
  svg {
    margin-right: 10px;
  }
}

.pdtmm {
  table {
    margin-bottom: 0px !important;
    table-layout: auto;
  }
}

@media screen and (max-width: 535px) {
  #attachment-modal-table {
    .pagination {
      flex-wrap: wrap;
      li {
        margin-bottom: 10px;
      }
    }
  }
}
