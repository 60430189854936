.add-mortage-section {
	position: relative;
	margin-bottom: 36px;
	.add-mortage-bg {
	  position: absolute;
	  width: 100%;
	  height: 482px;
	  background: var(--form-bg-color);
	  clip-path: var(--form-bg-clip-path);
	  .flood-mortage-house1 {
		position: absolute;
		right: 25px;
		bottom: 60px;
		width: 304.28px;
		height: 234px;
		background-image: var(--rainy-building1-img);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	  }
	  .flood-mortage-house2 {
		position: absolute;
		left: 25px;
		bottom: 60px;
		width: 274.92px;
		height: 234px;
		background-image: var(--rainy-house1-img);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	  }
	}
	.flood-add-mortage-form-section {
	  min-height: 300px;
	  margin-top: 41px;
	  z-index: 10;
	  background: #ffffff;
	  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
	  border-radius: 5px;
	  padding: 32px 25px 32px 25px;
	  .add-mortage-form-main {
		padding-bottom: 17px;
		.add-mortage-headings {
		  margin-bottom: 30px;
		  margin-left: 10px;
		  h3 {
			font-size: var(--title-font-size);      
			font-weight: 600;
			line-height: 26px;
			text-align: left;
			color: #292929;
		  }
		}
		.add-mortage-form {
		  border: 1px solid var(--border-color);
		  border-radius: 8px;
		  padding: 14px 14px 40px 14px;
		  min-height: 230px;
		  .add-mortage-table-div {
			.add-mortage-table {
			  margin-bottom: 21px;
			  thead tr {
				background-color: #f4f4f4;
				height: 51px;
			  }
  
			  thead tr th {
				font-size: var(--label-font-size);      
				font-weight: 500;
				line-height: 19px;
				color: #424242;
				vertical-align: middle;
				text-align: start;
				min-width: 110px;
				padding-left: 20px;
			  }
  
			  tbody tr td {
				padding: 4px 0.5rem 2px 0.5rem !important;
			  }
			  tbody tr td input {
				max-width: 120px;
			  }
			  tbody tr td:not(:last-child) {
				padding-right: 30px !important;
			  }
			  .add-mortage-action-row {
				vertical-align: middle;
				.add-mortage-action {
				  display: flex;
				  justify-content: start;
				  align-items: center;
				  padding-left: 8%;
				  img {
					cursor: pointer;
				  }
				}
			  }
			}
		  }
		  .add-mortage-add-btn {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 11px;
			.btn {
			  font-size: 16px;
			  font-weight: 400;
			}
			#add-input-field {
			  font-size: var(--label-font-size);      
			  font-weight: 400;
			  line-height: 19px;
			  padding: 3px 17px 3px 17px;
			  border-radius: 30px;
			  span {
				font-size: 20px;
				font-weight: 400;
				line-height: 24px;
			  }
			}
		  }
  
		  .add-mortage-searchbar {
			position: relative;
			min-width: 300px;
			min-height: 33px;
			border-radius: 26px;
			border: 1px solid rgba(41, 69, 71, 0.2);
			.add-mortage-input {
			  border: 0px solid #ffffff;
			  margin-left: 10px;
			  box-shadow: none;
			  outline: none;
			  color: #292929;
			  font-size: 14px;
			  font-weight: 400;
			  line-height: 16.94px;
			  vertical-align: bottom;
			  width: 70%;
			}
			#add-mortage-search-icon {
			  position: absolute;
			  right: 0;
			  height: 100%;
			  border-radius: 8px;
			  line-height: 10px !important;
			  width: 72px;
			  border-radius: 26px;
			}
		  }
		}
	  }
	  .add-mortage-actions-btns {
		display: flex;
		justify-content: center;
		margin-top: 21px;
	  }
	}
	.add-mortage-action-btns {
	  display: flex;
	  justify-content: center;
	  margin-top: 40px;
	}
  }
  .add-mortage-modal {
	#add-mortage-modal-header {
	  background: #66e3d8  !important;
	  border-bottom: 1px solid #66e3d8;
	  color: #ffffff;
	  border-radius: 0px;
	  padding-left: 50px;
	}
	.add-mortage-modal-body {
	  padding: 30px 50px 30px 50px;
	}
	.add-mortage-modal-footer {
	  display: flex;
	  justify-content: center;
	  padding-bottom: 1rem;
	}
	.add-mortage-form-group {
	  margin-bottom: 1rem;
	}
  }
  
  @media screen and (max-width: 466px) {
	.add-mortage-add-btn {
	  flex-direction: column;
	  .add-mortage-searchbar {
		margin-bottom: 10px;
		min-width: 100% !important;
	  }
	}
  }
  
  @media screen and (max-width: 280px) {
	.add-mortage-section .flood-add-mortage-form-section {
	  padding: 10px !important;
	}
	.add-mortage-searchbar {
	  #add-mortage-search-icon {
		width: 30% !important;
	  }
	}
  }
  