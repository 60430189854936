.thank-you-section {
    position: relative;
    margin-bottom: 26px;
    .thank-you-form-section {
      min-height: 300px;
      margin-top: 41px;
      z-index: 10;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
      border-radius: 5px;
      padding: 70px 29px 18px 29px;
  
      .thank-you-form-main {
        border-radius: 8px;
        border: 1px solid #64d6cd;
        padding: 15px 10px 15px 10px;
        .thank-you-main {
          background: #f9ffff;
          border-radius: 8px;
          .thank-you-heading {
            min-height: 89px;
            background-color: #f4f4f4;
            display: flex;
            justify-content: center;
            align-items: center;
            h6 {
              font-size: 50px;
              font-style: normal;
              font-weight: 500;
              line-height: 61px;
              color: #42ccc2;
              text-align: center;
              margin: 0px;
            }
          }
          .thank-you-details {
            padding-top: 50px;
            padding-bottom: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
            img {
              margin-right: 20px;
            }
            p {
              text-align: center;
              max-width: 369px;
              font-size: 18px;
              font-style: italic;
              font-weight: 400;
              line-height: 30px;
              color: #000000;
  
              span {
                color: #42ccc2;
              }
            }
          }
        }
      }
      .pay-now-action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 28px;
        margin-top: 23px;
        #credit-card-btn {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: center;
          display: flex;
          align-items: center;
          padding: 7.5px 25px 7.5px 25px;
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 544px) {
    .thank-you-bg {
      .thank-you-house1 {
        display: none;
      }
      .thank-you-house2 {
        display: none;
      }
    }
  }
  
  @media screen and (max-width: 433px) {
    .thank-you-details {
      flex-direction: column;
      img {
        margin-right: 0px !important;
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 280px) {
    .thank-you-section .thank-you-form-section {
      padding: 10px !important;
    }
  }
  