.flood-discount-section {
  position: relative;
  margin-bottom: 27px;
  .flood-discount-form-section {
    min-height: 300px;
    margin-top: 27px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 40px 10px 40px 10px;
    #discount-form-one-row {
      margin-bottom: 50px;
    }
    .flood-discount-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 24px;
      .flood-discount-title {
        .flood-discount-main-title {
          font-size: var(--title-font-size);      
          font-weight: 600;
          line-height: 22px;
          text-align: left;
          color: #292929;
        }
        .flood-discount-main-desc {
          margin-top: 12px;
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 17px;
          text-align: left;
          color: #424242;
        }
      }
      .flood-discount-pricing {
        width: 181px;
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12px;
        .flood-discount-pricing-title {
          font-size: var(--estimate-premium-title-font-size);
          font-weight: 400;
          line-height: 19px;

          text-align: center;
          color: #292929;
        }
        .flood-discount-pricing-value {
          font-size: var(--estimate-premium-value-font-size);
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: center;
          color: #64d6cd;
          margin-bottom: 0px;
          span {
            font-size: var(--estimate-premium-span-font-size);
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }
    }
    .discount-percentage-icon {
      background-image: var(--flood-dicount-percentage-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      max-width: 199px;
      height: 118px;
      margin-bottom: 41px;
    }
    .discount-house-check3-icon {
      background-image: var(--discount-three-home-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100px;
      height: 97px;
      margin-bottom: 24px;
    }

    .discount-house-check-icon {
      background-image: var(--discount-house-check-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 112.28px;
      height: 103px;
      margin-bottom: 24px;
      margin-top: 15px;
    }
    .discount-health-icon {
      background-image: var(--discount-health-img);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 89px;
      height: 127px;
      margin-bottom: 50px;
      margin-top: 30px;
    }
    #flood-discount-one-row {
      padding: 24px 16px 24px 16px !important;
    }
    .flood-discount-row {
      margin-bottom: 41px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      .flood-discount-question-div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p {
          margin: 0px;
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 26px;
          color: #424242;
          margin-right: 1rem;
        }
        span {
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 26px;
          color: #000000;
        }
        .flood-discount-row-actions {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;

          .flood-discount-row-switch {
            margin-left: 10px;
            margin-right: 2px;
          }
        }
      }
    }
    .discount-form-alert {
      background: #f9ffff;
      border-radius: 5px;
      border: 1px solid #f9ffff;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 13px 20px 13px 20px;
      img {
        margin-top: 2px;
        margin-right: 12px;
      }
      .discount-form-alert-text {
        font-size: 19px;      
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #000000;
        padding-right: 10px;
      }
    }
    #discount-form-policy-label {
      font-size: var(--label-font-size);      
      font-weight: 500;
      line-height: 17px;
      color: var(--discount-input-label-color);
    }
    #discount-form-policy-input {
      border: 1px solid #cdcdcd;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .discount-form-two-form {
      margin-bottom: 24px;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 13px;
      }
    }
    #flood-discount-date-picker {
      border-radius: 5px;
      outline: none;
    }
    .discount-footer-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      margin-bottom: 27px;
    }
    #discount-three-label {
      font-size: var(--label-font-size);      
    }
    .form-btns {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
    .discount-two-form-btns {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 542px) {
  .flood-discount-header {
    flex-direction: column;
    align-items: center !important;
    .flood-discount-title {
      .flood-discount-main-title {
        text-align: center !important;
      }
      .flood-discount-main-desc {
        text-align: center !important;
      }
    }
  }
  .flood-discount-question-div {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    p {
      text-align: center;
    }
    .flood-discount-row-actions {
      margin-top: 15px;
    }
  }
  #discount-one-fields {
    margin-bottom: 16px;
  }
}
