.marketing-tools-page-section {
  .marketing-tools-page-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    color: #424242;
    margin-left: 12px;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  #marketing-document-tab-row {
    padding: 60px 40px 60px 40px;
  }
  .marketing-document-tab-card {
    background: #ffffff;
    border: 1.5px solid #d8d8d8;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 22px 22px 12px 22px;
    cursor: pointer;
    margin-bottom: 33px;
    img {
      margin-right: 15px;
      margin-bottom: 10px;
    }
    span {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: capitalize;
      color: #424242;
    }
    &:hover {
      background-color: #d8d8d8;
    }
  }
}
.cobranding-tab-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #424242;
  margin-bottom: 20px;
}

.branding-tab-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 49px;
    height: 47px;
  }
  .brand-social-icon {
    margin-right: 40px;
    margin-top: 40px;
  }
}
#social-media-tab-row {
  padding: 30px 30px 20px 30px;
}
