.simple-navbar {
  background: #ffffff;
  padding: 0px 50px 0px 50px;
  .lng-select-drop-title-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
}

@media screen and (max-width: 360px) {
  .simple-navbar {
    padding: 0px !important;
  }
}
