.property-details-section {
  position: relative;
  margin-bottom: 48px;
  .hero-section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 58px;
  
    .h-1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21.78px;
      color: #64d6cd;
      letter-spacing: 0.005em;
      text-transform: uppercase;
      margin-bottom: 16px;
      z-index: 1;
      text-align: center;
    }
  
    h2 {
      color: var(--hero-font-color);
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 24px;
      z-index: 100;
    }
  }

  .form-search-input {
    display: flex;
    justify-content: space-around;
  }

  .form-btns {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}