.message-view-row {
  margin-top: 25px !important;
}
.float-right {
  margin-left: 40%;
}
.message-view-btn-col {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  .btn {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-bottom: 10px !important;
  }
}
