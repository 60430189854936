.quote-page-section {
  .quote-page-tab1 {
    background: #ffffff;
    border: 1px solid rgba(27, 43, 64, 0.2);
    box-sizing: border-box;
    border-radius: 0px 10px 10px 10px;
    padding: 52px 14px 52px 14px;
    .dashboard-group-forms {
      margin-bottom: 30px;
    }
  }
}

.quote-page-tab2 {
  background: #ffffff;
  border: 1px solid rgba(27, 43, 64, 0.2);
  box-sizing: border-box;
  border-radius: 0px 10px 10px 10px;
  padding: 140px 14px 140px 14px;
}

.quote-page-cards-div {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 408px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
  .quote-page-cards-icon {
    color: white;
    z-index: 1;
    width: 131.74px;
    height: 105.57px;
    margin-bottom: 20px;
    object-fit: contain;
  }
  .quote-page-cards-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    color: #424242;
    z-index: 1;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.qpc1 {
  background: linear-gradient(
    180deg,
    #c6bca0 0%,
    rgba(240, 230, 202, 0.5) 100%
  );
  .quote-page-cards-bg1 {
    position: absolute;
    bottom: 50px;
    right: 0%;
    width: 150px;
    height: 150px;
    background-image: url("../../assets/c1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
  }
  .quote-page-cards-bg2 {
    position: absolute;
    bottom: 20px;
    right: 30%;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/c1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.qpc2 {
  background: linear-gradient(
    180deg,
    rgba(100, 214, 205, 0.7) 0%,
    rgba(100, 214, 205, 0.5) 100%
  );
  .quote-page-cards-bg1 {
    position: absolute;
    bottom: 50px;
    left: 0%;
    width: 150px;
    height: 150px;
    background-image: url("../../assets/c2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
  }
  .quote-page-cards-bg2 {
    position: absolute;
    bottom: 20px;
    left: 30%;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/c2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.qpc3 {
  background: linear-gradient(
    180deg,
    rgba(51, 209, 77, 0.7) 0%,
    rgba(51, 209, 77, 0.5) 100%
  );
  .quote-page-cards-bg1 {
    position: absolute;
    bottom: 50px;
    right: 0%;
    width: 150px;
    height: 150px;
    background-image: url("../../assets/c3.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
  }
  .quote-page-cards-bg2 {
    position: absolute;
    bottom: 20px;
    right: 30%;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/c3.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.qpc4 {
  background: linear-gradient(
    180deg,
    rgba(38, 97, 173, 0.7) 0%,
    rgba(38, 97, 173, 0.5) 100%
  );
  .quote-page-cards-bg1 {
    position: absolute;
    bottom: 50px;
    left: 0%;
    width: 150px;
    height: 150px;
    background-image: url("../../assets/c4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
  }
  .quote-page-cards-bg2 {
    position: absolute;
    bottom: 20px;
    left: 30%;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/c4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.qpc5 {
  background: linear-gradient(
    180deg,
    rgba(235, 235, 235, 0.7) 0%,
    rgba(1, 7, 15, 0.5) 100%
  );
  .quote-page-cards-bg1 {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 150px;
    height: 150px;
    background-image: url("../../assets/c4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
  }
  .quote-page-cards-bg2 {
    position: absolute;
    bottom: 20px;
    left: 30%;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/c4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.space-between-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start-class {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.justify-start-class {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.search-quote-form-group {
  margin-bottom: 30px;
}

.search-quote-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.search-quote-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-left: 13px;
}

.search-quote-tab-div {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0px 0px 10px 10px;
}

.search-quote-btns-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 20px 0px 20px;
  .btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #search-quote-btn {
    background: #ffffff;
    border: 1.5px solid #64d6cd;
    border-radius: 4px;
    color: #424242;
    box-shadow: none !important;
    &:hover {
      background: #64d6cd;
      border: 1.5px solid #ffffff;
      color: #ffffff;
    }
  }
  #close-all-btn {
    background: #d6f0ee;
    border: 1.5px solid #d6f0ee;
    border-radius: 4px;
    box-shadow: none !important;
    color: #424242 !important;
    &:hover {
      background: #64d6cd;
      border: 1.5px solid #ffffff;
      color: #ffffff;
    }
  }
}

.search-quote-card-header {
  background: #64d6cd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px 12px 32px;
  span {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
  }
  .accordion-button {
    padding: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .accordion-button::after {
    background-image: url(../../assets/plus-sign.png);
    background-size: contain;
    width: 1rem;
    height: 1rem;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url(../../assets/minus-sign.svg);
    height: 3px;
    background-size: auto;
  }
}
.search-quote-card-body {
  padding: 20px;
}

.coverage-card-main-div {
  border: 1px solid #d8d8d8;
  height: 500px;
  overflow: auto;
}

.quote-tab-info-table {
  tr {
    vertical-align: baseline;
  }
  tr th {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #424242;
  }
}

.application-tab-body {
  background: #ffffff;
  border: 1px solid rgba(27, 43, 64, 0.2);
  border-radius: 4px;
  padding-bottom: 10px;
}

.null-table-date-div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #424242;
  }
  .btn {
    width: fit-content;
    padding: 8px 20px 8px 20px !important;
    margin-bottom: 30px;
  }
}

.checkbox-table-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .table-checkbox {
    margin-right: 10px;
  }
}

.search-quote-question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 30px;
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  color: #424242;
}

.search-quote-question-body {
  margin-top: 15px;

  .search-questions-subdiv {
    .search-questions-subdiv-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: #424242;
      padding: 16px 20px 16px 20px;
      display: flex;
    }
    .search-questions-list {
      margin-bottom: 10px;
      .list-group-item:first-child {
        border-radius: 0px !important;
      }
      .list-group-item {
        padding-left: 20px !important;
        border: 0px solid white !important;
        border-radius: none !important;
        background-color: #effcfb;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &::before {
          position: absolute;
        }
        span {
          margin-left: 20px;
        }
      }
    }
    #sear-question-list-answers {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      margin-left: 20px;
      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #424242;
      }
    }
  }
}
#search-questions-subdiv {
  background-color: transparent !important;
  &::before {
    display: none;
  }
  padding-left: 50px !important;
}

.underwriting-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #424242;
  display: flex;
  margin-bottom: 24px;
}

.quote-tab-questions-main-div {
  display: flex;
  flex-direction: column;
  color: #424242;
  margin-bottom: 22px;

  .quote-tab-questions-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 22px;
  }
  #quote-tab-questions-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.quote-type-subdiv {
  label {
    margin-left: 12px;
    vertical-align: middle;
  }
}

.activity-log-tab-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #424242;
  padding: 32px 32px 32px 32px !important;
}

#activity-log-tab-table-outer-div {
  background-color: #fff;
}
.activity-log-tab-table {
  .checkbox-table-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .table-checkbox {
      margin-right: 10px;
    }
  }
  #table-select-options {
    width: fit-content !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .cdtm-head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      svg {
        margin-right: 13px;
        width: 20px !important;
      }
    }
    text-align: center;
    thead {
      background: #f0f0f0;
      text-align: start;
      tr th {
        vertical-align: middle;
        font-size: 17px;
        font-weight: 400;
        line-height: 18px;
        color: #424242;
        padding-top: 15px;
        padding-bottom: 15px;
        svg {
          cursor: pointer;
        }
      }
    }
    thead tr th:first-child {
      padding-left: 30px;
    }
    thead tr th:last-child {
      padding-right: 30px;
    }
    tbody tr td:first-child {
      padding-left: 30px;
    }
    tbody tr td:last-child {
      padding-right: 30px;
    }
    tbody {
      background-color: #ffff;

      tr {
        td {
          vertical-align: middle;
          font-size: 17px;
          font-weight: 400;
          line-height: 18px;
          color: #424242;
          text-align: start;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
    tbody tr:last-child td {
      border-bottom: 0px solid white !important;
    }
  }
  table tr th,
  table tr td {
    border-right: 0px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  table tr th {
    text-align: left;
  }

  .table-main-footer-div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 0px 30px 0px 30px;
    .table-main-footer-entries {
      font-size: 17px;
      font-weight: 400;
      line-height: 18px;
      color: #424242;
      margin-bottom: 20px;
    }
    .table-main-footer-pagination {
      margin-bottom: 20px;

      .pagination {
        font-size: 18px !important;
        li {
          padding-right: 8px;
          .page-link {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .page-item.active .page-link {
          background-color: #fff !important;
          color: #64d6cd;
          border: 1px solid #64d6cd;
        }
      }
    }
  }
  .table-main-link {
    color: #2a72d1 !important;
    text-decoration: none !important;
    font-weight: 500;
  }
}
#activity-log-tab-table-entries {
  padding: 20px 32px 20px 32px;
}

.activity-log-tab-bts-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  .btn {
    margin-right: 12px;
    height: 40px;
  }
}

#search-quote-uw {
  width: 100%;
  margin-right: 10px;
  svg {
    cursor: pointer;
  }
}

.check-question-radio {
  margin-right: 10px;
}
.dashboard-group-input.check-question-select {
  height: fit-content !important;
}

.custom-quote-accordion {
  .accordion-item {
    border: none !important;
    border-bottom: 1px solid #d8d8d8 !important;
  }
}
.search-agency-filters {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 22px 12px;
}

#search-agency-modal-table {
  padding-bottom: 32px;
  border-radius: 5px;
  #search-bar-0-label {
    display: none;
  }
  .search-agency-table-searchbar {
    display: flex;
    flex-direction: column;
    #search-bar-0 {
      box-shadow: none !important;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 50%;
      margin-left: 10px;
    }
    table {
      thead th {
        border-top: 1px solid #d8d8d8;
      }
    }
    .selection-cell-header {
      padding-right: 30px;
    }

    .pagination {
      flex-wrap: wrap;
      li {
        margin-bottom: 10px;
      }
    }
  }
}

#search-quote-tab-all-filters {
  padding: 32px 12px 0px 12px;
}

// Flood
.myform-input {
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 8px;
  height: 34px;
  color: #292929;
  font-size: var(--label-font-size);      
  font-weight: 400;
  line-height: 16.94px;
  box-shadow: none !important;
  &:focus {
    border: 1px solid #9c9898;
  }
}

.myform-label {
  font-size: var(--label-font-size);      
  font-weight: 500;
  line-height: 17px;
  color: #424242;
  margin-bottom: 8px;
}


.custom-main-swtich {
  .form-check-input {
    box-shadow: none !important;
    border-color: #424242;
  }
  .form-check-input:checked {
    background-color: var(--switch-color);
    border-color: var(--switch-color);
  }
}

//To Remove Validation Mark Image use prevent-validation Class
.was-validated .prevent-validation.form-control:valid,
.prevent-validation.form-control.is-valid {
  border-color: #ced4da;
  background-image: none;
}