.dashboard-container {
  display: grid;
  grid:
    "sidebar header" min-content
    "sidebar main" 1fr / min-content 1fr;
  min-height: 100vh;
  .sidebar-container {
    grid-area: sidebar;
    z-index: 2000;
  }
  .header-container {
    grid-area: header;
  }
  .dashboard-content-container {
    grid-area: main;
    margin: 17px;
  }
}
