.dashboard-navbar {
  background: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
  padding: 0px 10px 0px 10px !important;
  align-items: baseline !important;
  margin-bottom: 1rem;
  .navbar-toggler {
    padding-left: 0px !important;
  }
}
.navbar-info-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .vertical-line-bar {
    height: 25.5px;
    width: 2px;
    background: #d2d2d2;
    margin-right: 20px;
    margin-left: 20px;
  }
  .navbar-info-main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    span {
      font-size: 14.5px;
      font-weight: 500;
      line-height: 15px;
    }
  }
}

.lng-selector-drop {
  margin-right: 25px;
  background: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  padding: 0px 20px 0px 0px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #424242;

  .dropdown-toggle::after {
    display: inline !important;
    top: 15px !important;
    right: 10px !important;
    position: absolute;
  }
  .dropdown-menu {
    min-width: 100% !important;
    border-radius: 7px;
    border: none !important;
    margin-top: 16px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
  }
  .dropdown-item {
    &:hover {
      background-color: #edfffe;
    }
    &:focus {
      color: #212529;
    }
  }
}

.noti-selector-drop {
  border: none !important;
  margin-right: 25px;

  #basic-nav-dropdown::after {
    display: none;
  }
  .dropdown-menu {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);

    left: -200px !important;
    border-radius: 7px;
    border: none !important;
    margin-top: 14px !important;
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    &:hover {
      background-color: #edfffe;
    }
    &:focus {
      color: #212529;
    }
    .noti-text {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .noti-time {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #424242;
    }
  }
}

.noti-div {
  position: relative;
  svg {
    path {
      stroke: #d2d2d2 !important;
    }
  }
  .notification-alert {
    right: 0px;
    position: absolute;
    width: 10.37px;
    height: 10.37px;
    background-color: #ff1e1e;
    border-radius: 50%;
  }
}

.avatar-selector-drop {
  border: none !important;
  #basic-nav-dropdown::after {
    display: none;
  }
  .dropdown-menu {
    left: -100px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);

    border-radius: 7px;
    border: none !important;
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px 9px 20px;
    &:hover {
      background-color: #edfffe;
    }
    &:focus {
      color: #212529;
    }
    .noti-text {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .noti-time {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #424242;
    }
  }

  .avatar-name {
    font-size: 14px;
    color: #424242;
    font-weight: 600;
    line-height: 16px;
  }
  .avatar-role {
    font-size: 14px;
    color: #424242;
    font-weight: 400;
    line-height: 16px;
  }
  .avatar-edit {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    color: #64d6cd;
  }
  .avatar-logout {
    color: #64d6cd;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .dashboard-navbar {
    padding-top: 10px !important;
    .navbar-nav {
      align-items: flex-start !important;
    }
    .navbar-toggler {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    .navbar-info-div {
      flex-direction: column;
      align-items: flex-start;
      .navbar-info-main {
        margin-bottom: 10px;
      }
      .vertical-line-bar {
        display: none;
      }
    }
    .noti-div .notification-alert {
      left: 12px;
    }
    .lng-selector-drop {
      width: 100%;
      padding: 0px !important;
      border: 0px !important;
      .dropdown-menu {
        margin-top: 0px !important;
        box-shadow: none;
      }
      .dropdown-item {
        padding: 13px;
        &:hover {
          background-color: #edfffe;
        }
        &:focus {
          color: #212529;
        }
      }
    }
    .noti-selector-drop {
      width: 100%;
      .dropdown-menu {
        box-shadow: none;
      }
    }
    .avatar-selector-drop {
      width: 100%;
      .dropdown-menu {
        box-shadow: none;
      }
    }
  }
}

div#chatbotIcon {
  position:fixed;right:2%;bottom:4%;z-index:2030;cursor:pointer;border-radius:20px 5px 20px 20px;background-color:#ff6600;padding:20px;
}

input#wisdom {
    padding: 6px;
    font-size: 1em;
    width: 100%
  }

  input::placeholder {
    color: #ccc;
    font-style: italic;
  }

  p.userRequest {
    margin: 4px;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    min-width: 50%;
    max-width: 85%;
    float: left;
    background-color: #D68928;
  }

  div.lexResponse {
    margin: 4px;
    min-width: 50%;
    max-width: 85%;
    float: right;
  }

  div.lexResponseText {
    text-align: right;
    background-color: #64d6cd;
    font-style: italic;
    border-radius: 4px;
    display:block;
    padding: 4px 10px 4px 10px;
  }

  div.lexResponseIconDiv {
    max-height: 10%;
    // max-width: 10%;
    display:block;
    float: right;
    border:#424242 2px
  }

  .lexResponseIcon {
    height: 20px;
    width: 20px;
    padding: 2px;
  }

  p.lexError {
    margin: 4px;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    text-align: right;
    min-width: 50%;
    max-width: 85%;
    float: right;
    background-color: #f77;
  }

  div#chatbot {
    width: 340px; height: 500px; position:fixed; background-color: #eee; right:2%; bottom:8%; z-index:3000;visibility:hidden;border-radius:5px
  }

  div#chatbox {
    background-color:#102229; padding:10px; border-top-left-radius:5px; border-top-right-radius:5px ; padding-bottom:30px;
  }

  div#conversation {
    width: 90%; height: 80%; background-color: white; overflow: auto; margin-left:20px;z-index:3050;margin-right:20px;margin-top:-20px; border-radius:5px;margin-bottom:15px;
  }

  input#wisdom {
    border-bottom-left-radius:5px;border-bottom-right-radius:5px;
  }