.starting-estimate-section {
  position: relative;
  margin-bottom: 27px;
  .starting-estimate-form-section {
    min-height: 300px;
    margin-top: 27px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 0px 0px 40px 0px;
    .starting-estimate-header {
      min-height: 78px;
      background: var(--starting-estimate-header-color);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 39px;
      .starting-estimate-title {
        font-size: 27px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
        color: var(--starting-estimate-title-color);
      }
    }
    .starting-estimate-content {
      border: 1px solid #ebebeb;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 35px;
      padding-right: 35px;
      margin-bottom: 55px;
      .starting-estimate-icon {
        width: 92.95px;
        height: 97.46px;
        background-image: var(--starting-estimate-icon-image);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-bottom: 24px;
      }
      .starting-estimate-price {
        display: flex;
        align-items: baseline;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        margin-bottom: 24px;
        .se-slash {
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          letter-spacing: 0em;
        }
        .se-year {
          font-size: 27px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 0em;
        }
      }
      .starting-estimate-desc {
        font-size: 19px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #424242;
      }
    }
    .form-btns {
      display: flex;
      justify-content: center;
    }
  }
}
