:root {
    --card-title-font: 20px;

    --starting-estimate-icon-image: url("../assets/flood/dollar-icon-light.svg");
    --starting-estimate-header-color: linear-gradient(
      268.68deg,
      #64d6cd 0%,
      #98e8e1 41.15%,
      #cef2ef 100%
    );
    --starting-estimate-title-color: #292929;
    --contact-info-border-color: #64d6cd;
    --title-font-size: 27px;
    --label-font-size: 20px;
    --switch-color: #42ccc2;
    --border-color: #42ccc2;
    --modal-heading-color: #64d6cd;
    --starting-estimate-icon-image: url("../assets/flood/dollar-icon-light.svg");
    --contact-rainy-building-image: url("../assets/flood/rainy-building2-light.svg");
    --contact-rainy-home-image: url("../assets/flood/rainy-house2-light.svg");
    --flood-dicount-percentage-img: url("../assets/flood/flood-percent-light.svg");
    --discount-house-check-img: url("../assets/flood/house-check-light.svg");
    --discount-input-label-color: #64d6cd;
    --discount-health-img: url("../assets/flood/health-icon-light.svg");
    --starting-estimate-title-color: #292929;
    --discount-three-home-img: url("../assets/flood/flood-discount3-light.svg");
    --flood-pay-now-color: #64d6cd;
    --pay-mortage-icon: url("../assets/flood/mortage-icon-light.svg");
    --flood-no-data-icon: url("../assets/flood/no-data-icon-light.svg");
    --estimate-premium-title-font-size: 19px;
    --estimate-premium-value-font-size: 27px;
    --estimate-premium-span-font-size: 21px;
    --credit-card1-img: url("../assets/flood/credit1-light.svg");
    --credit-card2-img: url("../assets/flood/credit2-light.svg");

    /*Home Page*/
    --home-usage-vector1: url("../../src/assets/information/vector1-light.svg");
    --home-usage-vector2: url("../assets/information/vector2-light.svg");
    --home-usage-vector3: url("../assets/information/vector3-light.svg");
    --home-usage-vector4: url("../assets/information/vector4-light.svg");
    --home-updates-border: #64d6cd;

    /*Property Info*/
    --property-info-home-icon: url("../assets/information/house-icon-light.svg");
    --border-main-color: #64d6cd;

    /*Pay Now*/
    --credit-card1-img: url("../assets/credit1-light.svg");
    --credit-card2-img: url("../assets/credit2-light.svg");

    --architectural-day-img: url("../assets/roof-type/architecturalday.png");
    --metal-day-img: url("../assets/roof-type/metal_day.png");
    --other-day-img: url("../assets/roof-type/other_day.png");
    --threetab-day-img: url("../assets/roof-type/threetab_day.png");
    --tile-day-img: url("../assets/roof-type/tile_day.png");
  }
  