.dashboard-page-container {
  .dashbaord-select-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .select-ym-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #424242;
    margin-right: 39px;
    margin-bottom: 10px;
  }
  .dashboard-page-selections {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #select-ym-option {
    width: 162px;
    margin-right: 39px;
    margin-bottom: 10px;
  }

  #dp-chart2-title {
    align-self: flex-start;
    margin-left: 30px;
  }
  .dashboard-stat-card {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    // min-height: 221px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;
    .dashboard-stat-title {
      font-size: 25px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;
      color: #ffffff;
      text-align: center;
      z-index: 1;
      padding-left: 10px;
      padding-right: 10px;
    }
    .dashboard-stat-value {
      font-size: 36px;
      font-weight: 700;
      line-height: 43px;  
      letter-spacing: 0em;
      color: #424242;
      z-index: 1;
    }
    table{
      margin-bottom: 9%;
      font-size: 21px;
      display: table;
      font-weight: 700;
    }
  }
  .dashboard-stat-card.c1 {
    background: linear-gradient(
      180deg,
      #c6bca0 0%,
      rgba(240, 230, 202, 0.5) 100%
    );
    .dashboard-stat-svg1 {
      position: absolute;
      bottom: 60px;
      right: 0px;
      width: 140px;
      height: 140px;
      background-image: url("../../assets/c1.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .dashboard-stat-svg2 {
      position: absolute;
      bottom: 20px;
      right: 60px;
      width: 100px;
      height: 100px;
      background-image: url("../../assets/c1.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
  }

  .dashboard-stat-card.c2 {
    background: linear-gradient(
      180deg,
      rgba(100, 214, 205, 0.7) 0%,
      rgba(100, 214, 205, 0.5) 100%
    );
    .dashboard-stat-svg1 {
      position: absolute;
      bottom: 60px;
      left: 0px;
      width: 140px;
      height: 140px;
      background-image: url("../../assets/c2.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
    .dashboard-stat-svg2 {
      position: absolute;
      bottom: 20px;
      left: 60px;
      width: 100px;
      height: 100px;
      background-image: url("../../assets/c2.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
  }

  .dashboard-stat-card.c3 {
    background: linear-gradient(
      180deg,
      rgba(51, 209, 77, 0.7) 0%,
      rgba(51, 209, 77, 0.5) 100%
    );
    .dashboard-stat-svg1 {
      position: absolute;
      bottom: 60px;
      right: 0px;
      width: 140px;
      height: 140px;
      background-image: url("../../assets/c3.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
    .dashboard-stat-svg2 {
      position: absolute;
      bottom: 20px;
      right: 60px;
      width: 100px;
      height: 100px;
      background-image: url("../../assets/c3.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
  }

  .dashboard-stat-card.c4 {
    background: linear-gradient(
      180deg,
      rgba(38, 97, 173, 0.7) 0%,
      rgba(38, 97, 173, 0.5) 100%
    );
    .dashboard-stat-svg1 {
      position: absolute;
      bottom: 60px;
      left: 0px;
      width: 140px;
      height: 140px;
      background-image: url("../../assets/c4.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
    .dashboard-stat-svg2 {
      position: absolute;
      bottom: 20px;
      left: 60px;
      width: 100px;
      height: 100px;
      background-image: url("../../assets/c4.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
    }
  }
}

.dashboard-table-main {
  text-align: center;
  table {
    table-layout: auto;
  }
  thead {
    background: #f0f0f0;
    height: 48px;
    tr th {
      vertical-align: middle;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 18px !important;
      color: #424242;
    }
  }
  tbody {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    tr {
      height: 48px;
      td {
        vertical-align: middle;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        color: #424242;
      }
    }
  }
}

.dashboard-page-chart-div {
  background: #ffffff;
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px 0px 20px 0px;
  .dp-chart-title {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #424242;
  }
  .dp-chart-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .dp-chart-footer-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #424242;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px;
      .dp-chart-circle1 {
        width: 16px;
        height: 16px;
        background: #64d6cd;
        border-radius: 50px;
        margin-right: 10px;
      }
      .dp-chart-circle2 {
        width: 16px;
        height: 16px;
        background: #d68928;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
  }
}

.dashboard-cards-table-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  color: #424242;
  margin-bottom: 25px;
}
