.property-info-section {
    position: relative;
    margin-bottom: 27px;

    .property-info-bg {
        position: absolute;
        width: 100%;
        height: 482px;
        background: var(--form-bg-color);
        clip-path: var(--form-bg-clip-path);

        .property-info-house1 {
            position: absolute;
            right: 25px;
            bottom: 60px;
            width: 257.3px;
            height: 182px;
            background-image: var(--contact-info-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .property-info-house2 {
            position: absolute;
            left: 25px;
            bottom: 60px;
            width: 296.63px;
            height: 223px;
            background-image: var(--discount-page-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .property-form-house1 {
            position: absolute;
            left: 25px;
            bottom: 60px;
            width: 257.3px;
            height: 182px;
            background-image: var(--contact-info-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .property-form-house2 {
            position: absolute;
            right: 25px;
            bottom: 60px;
            width: 311.24px;
            height: 175px;
            background-image: var(--property-details-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .property-info-form-section {
        min-height: 300px;
        margin-top: 41px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        padding: 32px 53px 39px 53px;

        .property-info-headings {
            margin-bottom: 30px;

            h3 {
                color: #292929;
                text-align: start;
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
            }
        }

        .property-info-form-main {
            border-radius: 8px;
            border: 1px solid var(--border-main-color);
            padding: 17px 30px 17px 30px;
            min-height: 300px;

            .ph-container {
                display: flex;
                justify-content: center;

                .property-home-icon {
                    width: 91.98px;
                    height: 80.65px;
                    background-image: var(--property-info-home-icon);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }

            .property-form-row {
                margin: 32px 0px 32px 0px;
                background: #ffffff;
                box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
                border-radius: 8px;
                margin-bottom: 24px;
                padding: 24px 16px 24px 16px;
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                p {
                    margin: 0px;
                    color: #424242;
                    font-size: var(--label-font-size);
                    font-weight: 500;
                    line-height: 17px;
                    margin-right: 10px;
                }

                span {
                    font-size: var(--label-font-size);
                    font-weight: 400;
                    line-height: 19px;
                }

                .property-form-actions {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;

                    .property-form-switch {
                        margin-left: 10px;
                        margin-right: 2px;
                    }
                }
            }

            .property-info-field {
                margin-bottom: 16px;

                .property-info-label {
                    color: var(--form-font-color);
                    margin-bottom: 8px;
                    font-size: var(--label-font-size);
                    font-weight: 500;
                    line-height: 17px;
                }
            }
        }

        .property-info-actions-btns {
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
    }

    .flood-bts-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .btn {
            margin-right: 12px;
            height: 40px;
        }
    }
}

@media screen and (max-width: 542px) {
    .property-form-row {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;

        p {
            text-align: center;
        }

        .property-form-actions {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 280px) {
    .property-info-form-section {
        padding: 10px 20px 10px 20px !important;

        .property-info-form-main {
            padding: 10px !important;
        }
    }
}