.pay-now-action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  margin-top: 23px;
  #credit-card-btn {
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 7.5px 25px 7.5px 25px;
    img {
      margin-right: 8px;
    }
  }
}
.flood-policy-no-div { 
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  .flood-policy-no-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    p {
      margin: 0px;
      font-size: 20px;
      font-weight: 400;
      line-height: 16px;
      color: #424242;
      margin-right: 1rem;
    }
    .flood-policy-no {
      display: flex;
      font-size: 17px;
      font-weight: 500;
      justify-content: flex-end;
      align-items: baseline;
    }
  }
}
#flood-pay-now-form-main {
  border: 0px solid white !important;
  margin-bottom: 22px !important;
}
#flood-pay-now-headings {
  background: #f4f4f4 !important;
}
#flood-payment-method-details {
  margin-top: 28px !important;

  background: #f4f4f4 !important;
}

#flood-pn-amount {
  color: var(--flood-pay-now-color) !important;
}

#flood-payment-method-title {
  font-size: 20px;
}
.credit-card-section {
  flex-wrap: wrap;
}

.credit-card-icon3 {
  background-image: var(--pay-mortage-icon);

  width: 39px;
  height: 31px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.pay-now-section {
  position: relative;
  margin-bottom: 26px;
  .pay-now-form-section {
    min-height: 300px;
    margin-top: 41px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    .pay-now-headings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(100, 214, 205, 0.1);
      border-radius: 5px 5px 0px 0px;
      min-height: 66px;
      padding-left: 44px;
      padding-right: 44px;
      h6 {
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
        color: #292929;
      }
      .yearly-plan {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 66px;
        padding: 13px;
        .pn-amount {
          font-size: 33px;
          font-weight: 500;
          line-height: 22px;
          color: #64d6cd;
          vertical-align: bottom;
        }
        .pn-slash {
          color: #424242;
          font-weight: 600;
        }
        .pn-year {
          color: #424242;
          font-size: 19px;
          font-weight: 600;
          line-height: 22px;
          vertical-align: bottom;
        }
      }
    }
    .pay-now-form-main {
      border-radius: 8px;
      border: 1px solid #64d6cd;
      margin-top: 1rem;
      margin-right: 25px;
      margin-left: 25px;
      margin-bottom: 60px;
      padding: 10px;

      .credit-card-section {
        padding-left: 14px;
        display: flex;
        justify-content: flex-start;
        .credit-card-main {
          min-height: 65px;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          border: 1px solid #e1ab37;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-bottom: 10px;
          max-width: 200px;
          min-width: 200px;
          .credit-card-h {
            font-size: 21px;
            font-weight: 700;
            line-height: 26px;
            color: #292929;
          }
          .credit-card-icon1 {
            width: 39px;
            height: 29.25px;
            background-image: var(--credit-card1-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
          .credit-card-icon2 {
            width: 43.4px;
            height: 31px;
            background-image: var(--credit-card2-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }

      .payment-method-title {
        font-size: 23px;
        font-weight: 400;
        line-height: 24px;
        color: #424242;
        margin-bottom: 23px;
        padding: 16px 0px 0px 16px;
      }
      .payment-method-details {
        display: flex;
        flex-direction: column;
        background: #f9ffff;
        border-radius: 8px;
        padding: 19px 16px 10px 16px;
        .pmd-heading {
          font-size: var(--label-font-size);      
          font-style: italic;
          font-weight: 400;
          line-height: 26px;
          margin-bottom: 2px;
          color: #000000;
          .avatar-insurance {
            font-size: 22px;
            font-style: italic;
            font-weight: 700;
            line-height: 26px;
          }
        }
        .pmd-address {
          font-size: var(--label-font-size);      
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          color: #424242;
          margin: 0;
        }
        .pmd-address-details {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          color: #424242;
          max-width: 197px;
          p {
            margin-bottom: 5px;
          }
        }

        .pmd-mortage-div {
          margin-top: 11px;
          .pmd-mortage-address {
            font-size: var(--label-font-size);      
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            color: #424242;
            margin: 0;
            margin-bottom: 6px;
          }
          .pmd-mortage-details {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            color: #424242;
            p {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 544px) {
  .pay-now-bg {
    .pay-now-house1 {
      display: none;
    }
    .pay-now-house2 {
      display: none;
    }
  }
}

@media screen and (max-width: 458px) {
  .credit-card-section {
    flex-direction: column;
  }
}
@media screen and (max-width: 374px) {
  .pay-now-headings {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    h6 {
      margin-bottom: 10px !important;
    }
  }
}

@media screen and (max-width: 256px) {
  .credit-card-section {
    .credit-card-main {
      min-width: 100px !important;
      margin-right: 0px !important;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
