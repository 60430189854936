.mailing-address-section {
    position: relative;
    margin-bottom: 27px;

    .mailing-address-bg {
        position: absolute;
        width: 100%;
        height: 482px;
        background: var(--form-bg-color);
        clip-path: var(--form-bg-clip-path);

        .mailing-address-house1 {
            position: absolute;
            right: 25px;
            bottom: 60px;
            width: 296.63px;
            height: 223px;
            background-image: var(--mailing-address-building);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .mailing-address-house2 {
            position: absolute;
            left: 25px;
            bottom: 60px;
            width: 311.35px;
            height: 144px;
            background-image: var(--contact-info-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .mailing-address-form-section {
        min-height: 300px;
        margin-top: 41px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        padding: 32px 49px 32px 49px;

        .mailing-address-headings {
            margin-bottom: 30px;

            h3 {
                color: #292929;
                font-size: var(--title-font-size);
                font-weight: 600;
                line-height: 26px;
            }
        }

        .mailing-address-form-main {
            border-radius: 8px;
            border: 1px solid var(--border-color);
            padding: 31px 29px 41px 29px;

            .mailing-address-card-row {
                margin-bottom: 19px;
                background: #ffffff;
                box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
                border-radius: 8px;
                margin-bottom: 24px;
                padding: 24px 16px 24px 16px;
                display: flex;
                flex-direction: column;

                .mailing-address-question-div {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;

                    p {
                        margin: 0px;
                        color: #424242;
                        font-size: var(--label-font-size);
                        font-weight: 500;
                        line-height: 17px;
                        margin-right: 10px;
                    }

                    span {
                        font-size: var(--label-font-size);
                        font-weight: 400;
                        line-height: 19px;
                    }

                    .mailing-address-card-actions {
                        display: flex;
                        justify-content: flex-start;
                        align-items: baseline;

                        .mailing-address-card-switch {
                            margin-left: 10px;
                            margin-right: 2px;
                        }
                    }
                }

                .mailing-address-card-input {
                    margin-top: 22px;
                }
            }
        }

        .mailing-address-actions-btns {
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
    }

    .flood-bts-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    
        .btn {
            margin-right: 12px;
            height: 40px;
        }
    }
}

@media screen and (max-width: 542px) {
    .mailing-address-question-div {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;

        p {
            text-align: center;
        }

        .mailing-address-card-actions {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 280px) {
    .mailing-address-section .mailing-address-form-section {
        padding: 10px !important;
    }
}