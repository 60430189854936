.waiting-period-section {
  position: relative;
  margin-bottom: 27px;
  .waiting-period-form {
    min-height: 300px;
    margin-top: 44.5px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    padding: 32px 42px 40px 42px;
    h1 {
      font-weight: 700;
      font-size: 18px;
      padding-top: 37px;
      text-align: start;
    }
    .confirm-address-form {
      padding: 32px 42px 40px 42px;
      .confirm-address-field {
        margin-bottom: 24px;
        .confirm-address-input {
          // border: 1px solid #cdcdcd;
          box-sizing: border-box;
          border-radius: 8px;
          height: 34px;
          color: #292929;
          font-size: var(--label-font-size);      
          font-weight: 400;
          line-height: 16.94px;
          box-shadow: none;
          &:focus {
            border: 1px solid #9c9898;
          }
        }
        .confirm-address-label {
          color: var(--form-font-color);
          margin-bottom: 8px;
          font-weight: 500;
          font-size: var(--label-font-size);      
          line-height: 16.94px;
        }
      }
      .form-btns {
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
    }
    .contact-form-btns {
      display: flex;
      justify-content: center;
      margin-bottom: 41px;
    }
  }

  #waiting-period-div {
    border: 1px solid var(--contact-info-border-color);
    border-radius: 5px;
    margin: 60px 40px 60px 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    h1 {
      font-weight: 700;
      font-size: var(--title-font-size);      
      padding-top: 0px;
      text-align: center;
      padding-bottom: 40px;
      text-align: start;
      text-transform: capitalize;
    }
    .confirm-address-form {
      padding: 0px;
      .form-btns {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

.custom-info-date-picker {
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 8px;
  height: 34px;
  color: #292929;
  font-size: var(--label-font-size);      
  font-weight: 400;
  line-height: 16.94px;
  box-shadow: none !important;
  padding: 10px;
  width: 100%;
  &:focus {
    border: 1px solid #9c9898;
  }
}

@media screen and (max-width: 300px) {
  .waiting-period-section #waiting-period-div {
    margin: 40px 5px 40px 5px;
  }
}
