.property-details-section {
    position: relative;
    margin-bottom: 48px;

    .property-details-img {
        position: absolute;
        width: 100%;
        height: 482px;
        background: var(--form-bg-color);
        clip-path: var(--form-bg-clip-path);

        .pd-building {
            position: absolute;
            right: 25px;
            bottom: 17%;
            width: 311.24px;
            height: 175px;
            background-image: var(--property-details-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .pd-table {
            left: 105px;
            position: absolute;
            bottom: 17%;
            max-width: 224.59px;
            max-height: 127.76px;

            img {
                width: 100%;
            }
        }

        .pd-tree {
            left: 25px;
            position: absolute;
            bottom: 17%;
            max-width: 153.37px;
            max-height: 140.88px;

            img {
                width: 100%;
            }
        }

        .dp-building {
            position: absolute;
            right: 25px;
            bottom: 15%;
            width: 296.63px;
            height: 223px;
            background-image: var(--discount-page-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .dp-home {
            position: absolute;
            left: 25px;
            bottom: 15%;
            width: 274.92px;
            height: 234px;
            background-image: var(--discount-page-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .ci-home1 {
            position: absolute;
            right: 25px;
            bottom: 17%;
            width: 311.35px;
            height: 144px;
            background-image: var(--contact-info-house-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .ci-home2 {
            position: absolute;
            left: 25px;
            bottom: 17%;
            width: 257.3px;
            height: 182px;
            background-image: var(--contact-info-building-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .p-form {
        min-height: 300px;
        margin-top: 44.5px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;

        h1 {
            font-weight: 700;
            font-size: var(--title-font-size);
            padding-top: 37px;
            text-align: start;
        }

        .confirm-address-form {
            padding: 32px 42px 40px 42px;

            .confirm-address-field {
                margin-bottom: 24px;

                .confirm-address-input {
                    //border: 1px solid #cdcdcd;
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 34px;
                    color: #292929;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 16.94px;
                    box-shadow: none;

                    &:focus {
                        border: 1px solid #9c9898;
                    }
                }

                .confirm-address-label {
                    color: var(--form-font-color);
                    margin-bottom: 8px;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 16.94px;
                }
            }

            .form-map {
                background-color: #fff;
                border: 1px solid #d5cdcd;
                box-sizing: border-box;
                border-radius: 8px;
                height: 232px;
            }

            .form-map>div>div {
                border-radius: 8px;
            }

            .form-btns {
                display: flex;
                justify-content: center;
                margin-top: 24px;
            }
        }

        .contact-form-btns {
            display: flex;
            justify-content: center;
            margin-bottom: 41px;
        }
    }

    #contact-info-div {
        border: 1px solid var(--contact-info-border-color);
        border-radius: 5px;
        margin: 40px;
        padding-top: 37px;
        padding-bottom: 37px;

        h1 {
            font-weight: 700;
            font-size: var(--title-font-size);
            padding-top: 0px;
            text-align: center;
            padding-bottom: 40px;
            text-align: start;
            text-transform: capitalize;
        }

        .confirm-address-form {
            padding: 0px;

            .form-btns {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }
        }

        #contact-phone-number {
            display: block;
            outline: none !important;
            width: 100%;
            padding-left: 10px;
            color: #292929;
            font-size: 17px;
            font-weight: 400;
            line-height: 16.94px;
            font-family: "Inter", sans-serif !important;
        }
    }

    /* Discount Form */
    .discount-form {
        margin-top: 44.5px;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        padding: 40px 48px 40px 48px;

        h1 {
            text-align: start;
            font-weight: 700;
            font-size: var(--title-font-size);
            text-align: start;
            margin-bottom: 40px;
        }

        .discount-form-row {
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(78, 78, 78, 0.15);
            border-radius: 8px;
            margin-bottom: 24px;
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 17px;

            p {
                margin: 0px;
                color: #424242;
                line-height: 19.36px;
                font-size: 17px;
                font-weight: 400;
            }

            .discount-form-actions {
                display: flex;
                justify-content: flex-start;
                align-items: baseline;

                .discount-form-switch {
                    margin-left: 10px;
                    margin-right: 2px;
                }

                .form-check-input {
                    box-shadow: none !important;
                    border-color: #424242;
                }

                .form-check-input:checked {
                    background-color: var(--switch-color);
                    border-color: var(--switch-color);
                }
            }
        }

        .flood-bts-div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        
            .btn {
                margin-right: 12px;
                height: 40px;
            }
        }
    }
}